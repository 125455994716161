import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class PrixService {

    constructor(){
        this.adresse = new AdresseIP()
        this.backendUrl="service/api/dgri/categories-prix";
    }


    getAll() {
       
        return axios.get(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}`).then(res => res.data)
    }
   

    create(newPrix) {
        return axios.post(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}`,newPrix)
                    .then(res => res.data);
    }
   
    update(idPrix ,newPrix) {
       
        return axios.put(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}/${idPrix}`, newPrix)
                    .then(res => res.data);
    }

    delete(idPrix) {
    
        return axios.patch(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}/${idPrix}`)
                    .then(res => res.data)
    }


}