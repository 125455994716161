import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class TypeCategoriePrixService {

    constructor(){
        this.adresse = new AdresseIP()
        this.backendUrl="service/api/dgri/types-categories-prix";
    }


    getAll() {
       
        return axios.get(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}`).then(res => res.data)
    }
   

    create(newTypeCategoriePrix) {
        return axios.post(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}`,newTypeCategoriePrix)
                    .then(res => res.data);
    }
   
    update(idTypeCategoriePrix ,newTypeCategoriePrix) {
       
        return axios.put(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}/${idTypeCategoriePrix}`, newTypeCategoriePrix)
                    .then(res => res.data);
    }

    delete(idTypeCategoriePrix) {
    
        return axios.patch(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}/${idTypeCategoriePrix}`)
                    .then(res => res.data)
    }


}