import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown'

import { TypeCategoriePrixService } from '../../service/TypeCategoriePrixService';
import { PrixService } from '../../service/PrixService';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InputTextarea } from 'primereact/inputtextarea';

function Prix() {
   let emptyCategoriePrix = {
              id: null,
              nom: '',
              type: 'EXCELLENCE',
              idTypeCategoriePrix: null,
              typeCategoriePrix: null,
              montantRecompense: null,
              description: null
          };
      
          const [categoriePrixs, setCategoriePrixs] = useState([]);
          const [typeCategoriePrixs, setTypeCategoriePrixs] = useState([]);
          const [categoriePrixDialog, setCategoriePrixDialog] = useState(false);
          const [deleteCategoriePrixDialog, setDeleteCategoriePrixDialog] = useState(false);
          const [categoriePrix, setCategoriePrix] = useState(emptyCategoriePrix);
          const [selectedCategoriePrixs, setSelectedCategoriePrixs] = useState(null);
          const [submitted, setSubmitted] = useState(false);
          const [globalFilter, setGlobalFilter] = useState(null);
          const [loading, setLoading] = useState(false);
          const dt = useRef(null);
          const typeCategoriePrixService = new TypeCategoriePrixService();
          const categoriePrixService = new PrixService();
          const notify = new Notify();
          const spinner = new Spinner();
      
      
          useEffect(() => {
              
              setLoading(true)
              categoriePrixService.getAll().then(data => {
                  setCategoriePrixs(data)
                  setLoading(false);
                  //notify.success();
              })
              .catch(error => {
                  console.log(error);
                  setLoading(false);
                  notify.echec(error.response.data.message);
              })

              typeCategoriePrixService.getAll().then(data => {
                setTypeCategoriePrixs(data)
            })
            .catch(error => {
                console.log(error);
            })
          }, []);
      
          const openNew = () => {
              setCategoriePrix(emptyCategoriePrix);
              setSubmitted(false);
              setCategoriePrixDialog(true);
          }
      
          const hideDialog = () => {
              setSubmitted(false);
              setCategoriePrixDialog(false);
          }
      
          const hideDeleteCategoriePrixDialog = () => {
              setDeleteCategoriePrixDialog(false);
          }
      
          const saveCategoriePrix = () => {
              setSubmitted(true);
      
              if (categoriePrix.nom.trim()) {
                  let _categoriePrix = {...categoriePrix};
                  _categoriePrix['idTypeCategoriePrix'] = categoriePrix.typeCategoriePrix.id
                  //console.log(_categoriePrix)
                  let cat = {
                    id : _categoriePrix.id,
                    nom : _categoriePrix.nom,
                    montantRecompense : _categoriePrix.montantRecompense,
                    description : _categoriePrix.description,
                    type : _categoriePrix.type,
                    idTypeCategoriePrix : _categoriePrix.typeCategoriePrix.id

                  }
                  
                  if (categoriePrix.id) {
                      categoriePrixService.update(categoriePrix.id, cat).then(data => {
                          console.log(data);
                          categoriePrixService.getAll().then(data => setCategoriePrixs(data));
                          notify.success();
                      })
                      .catch(error => {
                          console.log(error);
                          notify.echec(error.response.data.message);
                      })
                  }
                  else {
                      console.log(cat)
                      categoriePrixService.create(cat).then(data => {
                          console.log(data);
                          categoriePrixService.getAll().then(data => setCategoriePrixs(data));
                          notify.success();
                      })
                      .catch(error => {
                          console.log(error);
                          notify.echec(error.response.data.message);
                      })
                      
                  }
      
                  setCategoriePrixDialog(false);
                  setCategoriePrix(emptyCategoriePrix);
              }
          }
          const editCategoriePrix = (categoriePrix) => {
              setCategoriePrix({...categoriePrix});
              setCategoriePrixDialog(true);
          }
      
          const confirmDeleteCategoriePrix = (categoriePrix) => {
              setCategoriePrix(categoriePrix);
              setDeleteCategoriePrixDialog(true);
          }
      
          const deleteCategoriePrix = () => {
              categoriePrixService.delete(categoriePrix.id).then(data => {
                  setCategoriePrix(emptyCategoriePrix);
                  console.log(data);
                  categoriePrixService.getAll().then(data => setCategoriePrixs(data));
                  notify.success();
              })
              .catch(error => {
                  console.log(error);
                  notify.echec(error.response.data.message);
              })
              setDeleteCategoriePrixDialog(false);
              
          }
      
          const onChampsChange = (e, name) => {
              const val = (e.target && e.target.value) || '';
              let _categoriePrix = {...categoriePrix};
              _categoriePrix[`${name}`] = val;
      
              setCategoriePrix(_categoriePrix);
          }
      
          const actionBodyTemplate = (rowData) => {
              return (
                  <React.Fragment>
                      <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editCategoriePrix(rowData)} />
                      <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteCategoriePrix(rowData)} style={{marginLeft : 5}} />
                  </React.Fragment>
              );
          }
      
          const header = (
              <div className="table-header p-col-12">
                  <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                      <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                  </span>
              </div>
          );
          const categoriePrixDialogFooter = (
              <React.Fragment>
                  <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                  <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveCategoriePrix} />
              </React.Fragment>
          );
          const deleteCategoriePrixDialogFooter = (
              <React.Fragment>
                  <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCategoriePrixDialog} />
                  <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteCategoriePrix} />
              </React.Fragment>
          );
          const actionHeader = <Button type="button" icon="pi pi-cog"/>;
  return (
    <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable ref={dt} value={categoriePrixs} selection={selectedCategoriePrixs} onSelectionChange={(e) => setSelectedCategoriePrixs(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des catégoris de prix">

                    <Column field="nom" header="Categorie Prix" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="typeCategoriePrix.libelle" header="Type" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="montantRecompense" header="Montant" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
                </DataTable>
                }
            </div>
            <Dialog visible={categoriePrixDialog} style={{ width: '450px' }} header="Informations Categorie Prix" modal className="p-fluid" footer={categoriePrixDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="libelleCategoriePrix">Titre</label>
                    <InputText id="libelleCategoriePrix" value={categoriePrix.nom} onChange={(e) => onChampsChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': submitted && !categoriePrix.nom })} />
                    {submitted && !categoriePrix.nom && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="p-field">
                        <label htmlFor="libelleTypeCategoriePrix">Type catégorie prix</label>
                        <Dropdown optionLabel="libelle" value={categoriePrix.typeCategoriePrix} options={typeCategoriePrixs} onChange={(e) => onChampsChange(e, 'typeCategoriePrix')} required  />
                        {/*submitted && !categoriePrix.typeCategoriePrix.id && <small className="p-error">Ce champs est obligatoire.</small>*/}
                </div>
                <div className="p-field">
                    <label htmlFor="montant">Montant</label>
                    <InputText id="montant" value={categoriePrix.montantRecompense} onChange={(e) => onChampsChange(e, 'montantRecompense')} required className={classNames({ 'p-invalid': submitted && !categoriePrix.montantRecompense })} />
                    {submitted && !categoriePrix.montantRecompense && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="description">Description</label>
                    <InputTextarea id="description" value={categoriePrix.description} onChange={(e) => onChampsChange(e, 'description')}  />
                </div>
            </Dialog>

            <Dialog visible={deleteCategoriePrixDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteCategoriePrixDialogFooter} onHide={hideDeleteCategoriePrixDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {categoriePrix && <span>Etes vous sûr de supprimer cet élément ? <b>{categoriePrix.nom}</b>?</span>}
                </div>
            </Dialog>

        </div>
  )
}

export default Prix