import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class AppelCandidatureService {

    constructor(){
        this.adresse = new AdresseIP()
        this.backendUrl="service/api/dgri/appels-candidature";
    }


    getAll() {
       
        return axios.get(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}`).then(res => res.data)
    }
   

    create(newAppelCandidature) {
        return axios.post(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}`,newAppelCandidature)
                    .then(res => res.data);
    }
   
    update(idAppelCandidature ,newAppelCandidature) {
       
        return axios.put(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}/${idAppelCandidature}`, newAppelCandidature)
                    .then(res => res.data);
    }

    delete(idAppelCandidature) {
    
        return axios.patch(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}/${idAppelCandidature}`)
                    .then(res => res.data)
    }


}