import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { TypeCandidatureService } from '../../service/TypeCandidatureService';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';

function TypeCandidature() {
    let emptyTypeCandidature = {
        idTypeCandidature: null,
        libelleTypeCandidature: ''
    };

    const [typeCandidatures, setTypeCandidatures] = useState([]);
    const [typeCandidatureDialog, setTypeCandidatureDialog] = useState(false);
    const [deleteTypeCandidatureDialog, setDeleteTypeCandidatureDialog] = useState(false);
    const [typeCandidature, setTypeCandidature] = useState(emptyTypeCandidature);
    const [selectedTypeCandidatures, setSelectedTypeCandidatures] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const dt = useRef(null);
    const typeCandidatureService = new TypeCandidatureService();
    const notify = new Notify();
    const spinner = new Spinner();


    useEffect(() => {
        
        setLoading(true)
        typeCandidatureService.getAll().then(data => {
            setTypeCandidatures(data)
            setLoading(false);
            //notify.success();
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
            notify.echec(error.response.data.message);
        })
    }, []);

    const openNew = () => {
        setTypeCandidature(emptyTypeCandidature);
        setSubmitted(false);
        setTypeCandidatureDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTypeCandidatureDialog(false);
    }

    const hideDeleteTypeCandidatureDialog = () => {
        setDeleteTypeCandidatureDialog(false);
    }

    const saveTypeCandidature = () => {
        setSubmitted(true);

        if (typeCandidature.libelleTypeCandidature.trim()) {
            let _typeCandidature = {...typeCandidature};
            if (typeCandidature.idTypeCandidature) {
                typeCandidatureService.update(typeCandidature.idTypeCandidature, _typeCandidature).then(data => {
                    console.log(data);
                    typeCandidatureService.getAll().then(data => setTypeCandidatures(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
            }
            else {
                typeCandidatureService.create(_typeCandidature).then(data => {
                    console.log(data);
                    typeCandidatureService.getAll().then(data => setTypeCandidatures(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
                
            }

            setTypeCandidatureDialog(false);
            setTypeCandidature(emptyTypeCandidature);
        }
    }
    const editTypeCandidature = (typeCandidature) => {
        setTypeCandidature({...typeCandidature});
        setTypeCandidatureDialog(true);
    }

    const confirmDeleteTypeCandidature = (typeCandidature) => {
        setTypeCandidature(typeCandidature);
        setDeleteTypeCandidatureDialog(true);
    }

    const deleteTypeCandidature = () => {
        typeCandidatureService.delete(typeCandidature.idTypeCandidature).then(data => {
            setTypeCandidature(emptyTypeCandidature);
            console.log(data);
            typeCandidatureService.getAll().then(data => setTypeCandidatures(data));
            notify.success();
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        })
        setDeleteTypeCandidatureDialog(false);
        
    }

    const onChampsChange = (e, name) => {
        const val = (e.target && e.target.value).toUpperCase() || '';
        let _typeCandidature = {...typeCandidature};
        _typeCandidature[`${name}`] = val;

        setTypeCandidature(_typeCandidature);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editTypeCandidature(rowData)} />
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteTypeCandidature(rowData)} style={{marginLeft : 5}} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </span>
        </div>
    );
    const typeCandidatureDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveTypeCandidature} />
        </React.Fragment>
    );
    const deleteTypeCandidatureDialogFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTypeCandidatureDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteTypeCandidature} />
        </React.Fragment>
    );
    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable ref={dt} value={typeCandidatures} selection={selectedTypeCandidatures} onSelectionChange={(e) => setSelectedTypeCandidatures(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des Types candidature">

                    <Column field="libelleTypeCandidature" header="Type Candidature" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
                </DataTable>
                }
            </div>
            <Dialog visible={typeCandidatureDialog} style={{ width: '450px' }} header="Informations type candidature" modal className="p-fluid" footer={typeCandidatureDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="libelleTypeCandidature">Libelle Type candidature</label>
                    <InputText id="libelleTypeCandidature" value={typeCandidature.libelleTypeCandidature} onChange={(e) => onChampsChange(e, 'libelleTypeCandidature')} required autoFocus className={classNames({ 'p-invalid': submitted && !TypeCandidature.libelleTypeCandidature })} />
                    {submitted && !typeCandidature.libelleTypeCandidature && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteTypeCandidatureDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteTypeCandidatureDialogFooter} onHide={hideDeleteTypeCandidatureDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {typeCandidature && <span>Etes vous sûr de supprimer cet élément ? <b>{typeCandidature.libelleTypeCandidature}</b>?</span>}
                </div>
            </Dialog>

        </div>
    )
}

export default TypeCandidature