import React , {useState,useEffect,useRef} from 'react';
import { Button } from 'primereact/button';
import { ToastContainer } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Spinner } from '../../components/Spinner';
import { ParcoursUEService } from '../../lmd/service/ParcoursUEService';
import {SemestreService } from '../../lmd/service/SemestreService';
import { Notify } from '../../components/Notify';
import { Controller,useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import EnseignantService from '../service/EnseignantService';
import { CohorteServices } from '../service/CohorteServices';
import { TypeActiviteService } from '../../lmd/service/TypeActiviteService';
import { AnneeService } from '../../lmd/service/AnneeService';
import {PickList} from 'primereact/picklist';
import {InputText} from 'primereact/inputtext';
import { NiveauService } from '../../lmd/service/NiveauService';
import AffectationService from '../service/AffectationService';
import EmploiDuTempsService from '../service/EmploiDuTempsService';


export default function Affectation(){
    
    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded)
    const ref = useRef();

    const campusVpEip = localStorage.getItem('campusVpEip')
    const campusEnseignant = localStorage.getItem('campusEnseignant')
    const campusEtudiant = localStorage.getItem('campusEtudiant')
    const campusDirecteur = localStorage.getItem('campusDirecteur')
    const campusDa = localStorage.getItem('campusDa')
    const campusDepartement = localStorage.getItem('campusDepartement')
    const isVp = parseInt(JSON.parse(campusVpEip))
    const isEnseignant = parseInt(JSON.parse(campusEnseignant))
    const isEtudiant = parseInt(JSON.parse(campusEtudiant))
    const isDepartement = parseInt(JSON.parse(campusDepartement))
    const isDirecteur = parseInt(JSON.parse(campusDirecteur))
    const isDA = parseInt(JSON.parse(campusDa))
    

    const defaultValues = {
        anneeAcademique:'',
        parcours:'',
        semestre:'',
        elementConstitutif:'',
        typeActivite:'',
        enseignant:'',
        nbHeure:'',
        cohorte:'',
        departement:'',
        idElementConstitutifHoraire:'',
    }

    const defaultBilan = {
        libelleEC:'',
        restantHeureCours:'',
        restantHeureTD:'',
        restantHeureTP:''
    };

    let defaultDialog = {
        add:false,
        modify:false,
        delete:false
    }

    const spinner = new Spinner();
    const notify = new Notify();
    const parcoursUEService = new ParcoursUEService();
    const cohorteService = new CohorteServices();
    const semestreService = new SemestreService();
    const enseignantService = new EnseignantService();
    const typeActiviteService = new TypeActiviteService();
    const anneeService = new AnneeService();
    const niveauService = new NiveauService();
    const affectationService = new AffectationService();
    const emploiDuTempsService = new EmploiDuTempsService()

    const [cancelAndReset,setCancelAndReset] =useState(false);
    const [loading,setLoading] = useState(false);
    const [spin,setSpin] = useState(false);
    const [listParcours,setListParcours] = useState([]);
    const [listSemestre,setListSemestre] = useState([]);
    const [listeCohorte, setListeCohorte] = useState([]);
    const [listEC,setListEC] = useState([]);
    const [listEnseignant,setListEnseignant] = useState([]);
    const [listTypeActivite,setListTypeActivite] = useState([]) 
    const [TotalCohorte,setTotalCohorte] = useState([]);
    const [listTypeActiviteCohorte,setListTypeActiviteCohorte] =useState([]);
    const [target,setTarget] = useState([]);
    const [saveList,setSaveList] = useState([]);
    const [listAnnee,setListAnnee] = useState([]);
    const [listAffectation,setListAffectation] = useState([])
    const [listDepartement, setListDepartement] = useState([])
    const [listEtablissement, setListEtablissement] = useState([])
    const [etablissement, setEtablissement] = useState({})
    const [departement, setDepartement] = useState({})
    const [niveau, setNiveau] = useState({});
    const [listNiveau, setListNiveau] = useState([]);
    const [parcours, setParcours] = useState({});
    const [typeActivite, setTypeActivite] = useState({});
    const [cohorte, setCohorte] = useState({});
    const [semestre, setSemestre] = useState({});
    const [annee, setAnnee] = useState({});
    const [ecSelectionnee,setECSelectionnee] = useState([defaultBilan]);
    const [dialog,setDialog] = useState(defaultDialog);
    const [affectationDelete,setAffectationDelete] = useState();
    const [messageContainer,setMessageContainer] = useState({typeActivite:"",elementConstitutif:"",libelleCohorte:"",dialog:false})
    const {control,handleSubmit, reset,getValues,setValue,formState:{errors}} = useForm({defaultValues});
    const [globalFilter, setGlobalFilter] = useState(null);
    const [heureSuperieur,setHeureSuperieur] = useState(false);
    const [pickListChecker,setPickListChecker] = useState(false);
    const [noAvailable, setnoAvailable] = useState(false);
    const [enseignantSelect, setEnseignantSelect] =useState({selected:false,popup:false, enseignant:{}});


    let idDepartementEtab = token?token.code_departement:null;

    useEffect(() => {
        if(isDepartement){
            //console.log(idDepartementEtab)
            cohorteService.getListeParcoursDepartement(idDepartementEtab).then(data => {
                //console.log(data)
                setListParcours(data)
            })
            .catch(error => {
                console.log(error);
            })

        }

        else if(isDA || isDirecteur){
            cohorteService.getListeDepartementEtab(token.code_etablissement).then(data => {
                setListDepartement(data.departementEtabDtos)   
            })
            .catch(error => {
                console.log(error);
            })
        }
        else if(isVp){
            cohorteService.getListeEtabUniv(token.code_iesr).then(data => {
                setListEtablissement(data);
               
            })
            .catch(error => {
                console.log(error);
            })
        }

        anneeService.getAll().then(response=>{
            setListAnnee(response);
        });
        typeActiviteService.getAll().then(response=>{
            setListTypeActivite(response);
        });
        enseignantService.getAll().then(response=>{setListEnseignant(response);});

    }, []);

    const onEtablissementChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setEtablissement(val);
        cohorteService.getListeDepartementEtab(val.idEtablissement).then(data => {
            setListDepartement(data.departementEtabDtos);
        })
    }

    const onDepartementChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setDepartement(val);
        cohorteService.getListeParcoursDepartement(val.idDepartementEtab).then(data => {
            setListParcours(data);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const onAnneeChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setAnnee(val);
    }
    const onParcoursChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        
        if(field === "parcoursGet"){
            setParcours(val);
            
            niveauService.getListeNiveauByIdParcours(val.idParcours).then(response => {
                setListNiveau(response);
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })
            
        }
    }


    const onNiveauChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setNiveau(val);
    }


    const onTypeActiviteChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setTypeActivite(val);
        const idParcoursFiliere = parcours.idParcoursFiliere;
        const idNiveau = niveau.idNiveau;
        const idAnneeAcademique = annee.idAnneeAcademique;
        const idTypeActivite = val.idTypeActivite;

        cohorteService.getCohorteByTypeActivite(idParcoursFiliere, idNiveau, idAnneeAcademique, idTypeActivite).then(data => {
            if(data.length>0){
                setListeCohorte(data);
            }else{
                notify.echec("Aucune cohorte trouvée !!!")
            }
           
        })
        .catch(error => {
            console.log(error);
        })
    }


    const onCohorteChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setCohorte(val);
        emploiDuTempsService.getSemestre(val.idNiveau).then(data => {
            setListSemestre(data.semestreDtoList);
        })
        .catch(error => {
            console.log(error);
        })
    }


    const onSemestreChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setSemestre(val);
    }


    const listeActCohorte = () => {
        setLoading(true);
        if(isDepartement){
            affectationService.getAffectationCohorteSemestre(token.code_departement, cohorte.idCohorte, semestre.idSemestre).then(data => {
                setListAffectation(data);
            }).finally(()=>setLoading(false));
        }
        else if(isDA || isDirecteur || isVp){
            affectationService.getAffectationCohorteSemestre(departement.idDepartementEtab, cohorte.idCohorte, semestre.idSemestre).then(data => {
                setListAffectation(data);
            }).finally(()=>setLoading(false));
        }
        
    }


    const handleECSelectionner = (ecSelectionnee)=>{
        setECSelectionnee([{
            libelleEC : ecSelectionnee.libelleElementConstitutif,
            restantHeureCours : ecSelectionnee.nbHeureCours,
            restantHeureTD : ecSelectionnee.nbHeureTD,
            restantHeureTP : ecSelectionnee.nbHeureTP
        }]);
    }
    

    const parcoursUEHandler = (idParcours,semestre)=>{
        setListTypeActiviteCohorte([]);
        setValue("elementConstitutif","");
        setValue("typeActivite","");
        setListTypeActiviteCohorte([]);
        setTarget([]);
        parcoursUEService.get(idParcours,semestre.idSemestre).then(response=>{
            const list = [];
            response.forEach(resp=>{
                resp.uniteEnseignement.listeElementConstitutif.forEach(d=>{
                    list.push(d);
                })
            })
            setListEC(list);
        });

        niveauService.getAll().then(response=>{
            const niveau = response.find(resp=>resp.idNiveau === semestre.idNiveau);
            const annee = getValues("anneeAcademique");
            cohorteService.getCohorteParcoursNiveau(idParcours,niveau.idNiveau,annee.idAnneeAcademique)
            .then(response=>{setTotalCohorte(response)});
        });
      
    }


    const cohorteHandler=(typeActivite)=>{
        setTarget([]);
        setListTypeActiviteCohorte([]);
        const semestre = getValues("semestre");
        let parcours = getValues("parcours");

        niveauService.getAll()
        .then(response=>{
            const niveau = response.find(resp=>resp.idNiveau === semestre.idNiveau);
            const annee = getValues("anneeAcademique");
            cohorteService.getCohorteParcoursNiveau(parcours.idParcoursFiliere,niveau.idNiveau,annee.idAnneeAcademique)
            .then(response=>{
                let cohorteCours;
                switch(typeActivite.libelleTypeActivite){
                    case "Cours Magistral (CM)":
                        cohorteCours = response.filter(cohorte=>cohorte.idTypeActivite === typeActivite.idTypeActivite)
                        setListTypeActiviteCohorte(cohorteCours);
                        break
                    case "Travaux dirigés (TD)":
                        cohorteCours = response.filter(cohorte=>cohorte.idTypeActivite === typeActivite.idTypeActivite)
                        setListTypeActiviteCohorte(cohorteCours);
                        break
                    case "Travaux pratiques (TP)":
                        cohorteCours = response.filter(cohorte=>cohorte.idTypeActivite === typeActivite.idTypeActivite)
                        setListTypeActiviteCohorte(cohorteCours);   
                        break
                    default :
                        break
                }
            });
        });
    }


    const onHide = ()=>{
        if(dialog.add){
            setDialog(defaultDialog);
            setECSelectionnee([defaultBilan]);
            setSaveList([]);
            setSpin(false);
            reset();
            setListEC([]);
            setListSemestre([]);
            setListTypeActiviteCohorte([]);
            setTarget([]);
        }
    }


    const onSubmit=dataForm=>{
        setnoAvailable(false);
        var {anneeAcademique,elementConstitutif,enseignant,nbHeure,parcours,semestre,typeActivite} = dataForm;
        console.log("Donnees :",anneeAcademique,elementConstitutif,enseignant,nbHeure,parcours,semestre,typeActivite)
        console.log("Donnees Form :",dataForm)
        let listesCohortesDataForm = dataForm.cohorte.map(cohorte=>{
            return {
                    anneeAcademique,
                    elementConstitutif,
                    enseignant,
                    nbHeure:+nbHeure,
                    parcours,
                    semestre,
                    typeActivite,
                    cohorte
                }
            });

            //console.log(listesCohortesDataForm)

            listesCohortesDataForm.forEach(affect=>{
                let result = saveList
                .filter(save=>save.cohorte.idCohorte === affect.cohorte.idCohorte &&
                    save.elementConstitutif.idElementConstitutif === affect.elementConstitutif.idElementConstitutif &&
                    save.typeActivite.idTypeActivite === affect.typeActivite.idTypeActivite
                )
                .reduce((previousValue,currentValue)=>previousValue+currentValue.nbHeure,affect.nbHeure);

                //console.log(affect.typeActivite.libelleTypeActivite)
                //affect.typeActivite.libelleTypeActivite
                switch(affect.typeActivite.libelleTypeActivite){
                    case "Cours Magistral (CM)":
                        if(result <= affect.elementConstitutif.nbHeureCours){
                            setSaveList([...saveList,...listesCohortesDataForm]);
                        }else{
                            setHeureSuperieur(true);
                        }
                        //setSaveList([...saveList,...listesCohortesDataForm]);
                        break
                    case "Travaux dirigés (TD)":
                        if(affect.elementConstitutif.nbHeureTD >0){
                            if(result <= affect.elementConstitutif.nbHeureTD){
                                setSaveList([...saveList,...listesCohortesDataForm]);
                            }else{
                                setHeureSuperieur(true);
                            }
                            //setSaveList([...saveList,...listesCohortesDataForm]);
                        }else{
                            setnoAvailable(true);
                        }
                        
                        break
                        
                    case "Travaux pratiques (TP)":
                        if(affect.elementConstitutif.nbHeureTP>0){
                            if(result <= affect.elementConstitutif.nbHeureTP){
                                setSaveList([...saveList,...listesCohortesDataForm]);
                            }else{
                                setHeureSuperieur(true);
                            }
                        }else{
                            setnoAvailable(true);
                        }
                        
                        break
                    default :
                        break
                }
            })

    }
   
    const onSaveData = (dataForm = null)=>{
       
        if(isDA || isDirecteur) idDepartementEtab = departement.idDepartementEtab
        
        if(dialog.add){
            setSpin(true);
            let finalDTO = saveList.map(toSave=>{
                let idCohorte = toSave.cohorte.idCohorte;
                let idAnneeAcademique = toSave.anneeAcademique.idAnneeAcademique;
                let idElementConstitutif = toSave.elementConstitutif.idElementConstitutif;
                let idEnseignant = toSave.enseignant.idEnseignant;
                let nbHeure = +toSave.nbHeure;
                let idParcours  = toSave.parcours.idParcours;
                let idSemestre = toSave.semestre.idSemestre;
                let idTypeActivite  = toSave.typeActivite.idTypeActivite;
                let anneeDebut = toSave.anneeAcademique.anneeDebut;
                let anneeFin = toSave.anneeAcademique.anneeFin;
                let libelleParcours = toSave.parcours.libelleParcours;
                let libelleSemestre = toSave.semestre.libelleSemestre;
                let ordreSemestre = toSave.semestre.ordreSemestre;
                let libelleElementConstitutif = toSave.elementConstitutif.libelleElementConstitutif;
                let codeElementConstitutif = toSave.elementConstitutif.codeElementConstitutif;
                let creditElementConstitutif = toSave.elementConstitutif.creditElementConstitutif;
                let libelleTypeActivite = toSave.typeActivite.libelleTypeActivite;
                let libelleCohorte = toSave.cohorte.libelleCohorte;
                let matriculeEnseignant = toSave.enseignant.matriculeEnseignant;
                let nomEnseignant = toSave.enseignant.nomEnseignant;
                let prenomEnseignant = toSave.enseignant.prenomEnseignant;
            return{
                    idCohorte,
                    idAnneeAcademique,
                    idElementConstitutif,
                    idEnseignant,
                    nbHeure,
                    idParcours,
                    idSemestre,
                    idTypeActivite,
                    anneeDebut,
                    anneeFin,
                    idDepartementEtab,
                    libelleParcours,
                    libelleSemestre,
                    ordreSemestre,
                    libelleElementConstitutif,
                    codeElementConstitutif,
                    creditElementConstitutif,
                    libelleTypeActivite,
                    libelleCohorte,
                    matriculeEnseignant,
                    nomEnseignant,
                    prenomEnseignant
                };
            });

            //console.log(finalDTO)
                affectationService.createAffectionDepartement(idDepartementEtab,finalDTO)
                .then((response)=>{
                    setLoading(true)
                    setSaveList([]);
                    setECSelectionnee([defaultBilan]);
                    setListTypeActiviteCohorte([]);
                    setTarget([])
                    notify.success();
                    reset();
                    setEnseignantSelect({enseignant:{}, popup:false, selected:false});
                })
                .catch((errors)=>{
                    console.log(errors);
                    notify.echec(errors.response.data.message);
                })
                .finally(()=>{
                    setSpin(false);
                    setLoading(false);
                    setDialog({...dialog,add:false})
                })
        }else{
            
            setSpin(true);

            let finalDTO ={
                    idCohorte : dataForm.cohorte[0].idCohorte,
                    idAnneeAcademique : dataForm.anneeAcademique.idAnneeAcademique,
                    idElementConstitutif : dataForm.elementConstitutif.idElementConstitutif,
                    idEnseignant : dataForm.enseignant.idEnseignant,
                    nbHeure : dataForm.nbHeure,
                    idParcours : dataForm.parcours.idParcours,
                    idSemestre : dataForm.semestre.idSemestre,
                    idTypeActivite : dataForm.typeActivite.idTypeActivite,
                    anneeDebut : dataForm.anneeDebut,
                    anneeFin : dataForm.anneeDebut,
                    idDepartementEtab,
                    libelleParcours : dataForm.parcours.libelleParcours,
                    libelleSemestre : dataForm.parcours.libelleSemestre,
                    ordreSemestre : dataForm.semestre.ordreSemestre,
                    libelleElementConstitutif : dataForm.elementConstitutif.libelleElementConstitutif,
                    codeElementConstitutif : dataForm.elementConstitutif.codeElementConstitutif,
                    creditElementConstitutif : dataForm.elementConstitutif.creditElementConstitutif,
                    libelleTypeActivite : dataForm.elementConstitutif.libelleTypeActivite,
                    libelleCohorte : dataForm.cohorte[0].libelleCohorte,
                    matriculeEnseignant : dataForm.enseignant.matriculeEnseignant,
                    nomEnseignant : dataForm.enseignant.nomEnseignant,
                    prenomEnseignant: dataForm.enseignant.prenomEnseignant,
                    idElementConstitutifHoraire : getValues("idElementConstitutifHoraire"),
                };

                //console.log(finalDTO)
            affectationService.update(finalDTO)
            .then((response)=>{
                setLoading(true)
                setECSelectionnee([defaultBilan]);
                setListTypeActiviteCohorte([]);
                setTarget([])
                reset();
                setDialog({...dialog,modify:false})
                notify.success();
            })
            .catch(errors=>{
                notify.echec(errors.response.data.message);
                console.log(errors);
            })
            .finally(()=>{
                setLoading(false);
                setSpin(false);
            })
                    
        }
    }

   

    const handleModification = (dataToModify)=>{
        let anneeAcademique = listAnnee.find(annee=>annee.idAnneeAcademique === dataToModify.idAnneeAcademique);
        setValue("anneeAcademique",anneeAcademique);

        //Controole if ajout de ZTCHRISTIAN, raison d'ajout commentaire suivant
        //Pour resoudre un probleme lié à la disponibilité de la liste des parcours
        if(listParcours.length>0){

            let parcours = listParcours.find(parcour=>parcour.idParcours === dataToModify.idParcours);
            semestreService.get(parcours.idParcours).then(response=>{
                setListSemestre(response);
                let semestre = response.find(s=>s.idSemestre === dataToModify.idSemestre);
                setValue("semestre",semestre);

                parcoursUEService.get(parcours.idParcours,semestre.idSemestre).then(response=>{
                    const list = []
                        response.forEach(resp=>{
                            resp.uniteEnseignement.listeElementConstitutif.forEach(d=>{
                                list.push(d);
                            })
                        })
                    setListEC(list);
                    let elementConstitutif = list.find(ec=>ec.idElementConstitutif === dataToModify.idElementConstitutif);
                    setValue("elementConstitutif",elementConstitutif);
                    let libelleEC = elementConstitutif.libelleElementConstitutif;
                    let restantHeureCours = elementConstitutif.nbHeureCours;
                    let restantHeureTD = elementConstitutif.nbHeureTD;
                    let restantHeureTP = elementConstitutif.nbHeureTP;
        
                    setECSelectionnee([{libelleEC,restantHeureCours,restantHeureTD,restantHeureTP}]);

                    niveauService.getAll()
                    .then(response=>{
                        const niveau = response.find(resp=>resp.idNiveau === semestre.idNiveau);
                        cohorteService.getCohorteParcoursNiveau(parcours.idParcoursFiliere,niveau.idNiveau,anneeAcademique.idAnneeAcademique)
                        .then(response=>{
                            let cohorteTarget = response.find(cor=>cor.idCohorte === dataToModify.idCohorte);
                            setTarget([cohorteTarget]);
                            setValue("cohorte",[cohorteTarget]);
                        });
                    })
                });
            });
    
            let typeActivite  = listTypeActivite.find(activite=>activite.idTypeActivite === dataToModify.idTypeActivite);
            setValue("typeActivite",typeActivite);
            setValue("parcours",parcours);
            let enseignant = listEnseignant.find(enseignant=>enseignant.idEnseignant === dataToModify.idEnseignant);
            setValue("enseignant",enseignant);
            setEnseignantSelect({enseignant, selected:true});
            setValue("nbHeure", +dataToModify.nbHeure);
            setValue("idElementConstitutifHoraire",dataToModify.idElementConstitutifHoraire);
            setDialog({...dialog,modify:true});
        }
    }

    const handleAffectationDeletion = (dataToDelete={})=>{
        if(cancelAndReset){
            setSpin(true);
            setTimeout(()=>{
                setCancelAndReset(false);
                onHide();
            }
            
            ,2000);
        }else if(dialog.delete){
            setSpin(true);
            affectationService.delete(affectationDelete.idElementConstitutifHoraire)
            .then(()=>{
                notify.success(); // notification de success du traitement
                setLoading(true);
                affectationService.getAllAffectationDepartement(token.code_departement).then(response=>{setListAffectation(response)});
                setDialog({...dialog,delete:false});
            })
            .catch((error)=>{
                notify.echec(error.response.data.message); // notification d'echec
            })
            .finally(()=>{
                setSpin(false);
                setLoading(false);
            })

        }else{
            setAffectationDelete(dataToDelete)
            setDialog({delete:true})
        }
    }

    const pickListHanlder = e=>{
        setTarget(e.target)
        setListTypeActiviteCohorte(e.source);
        setValue("cohorte",e.target);
    }

    const removeItem =(key)=>{
        saveList.splice(key,1);
        setSaveList([...saveList]);
    }

    const cancelResetFooter =(

        <React.Fragment>
            <Button label="NON" type="button" disabled={spin} icon="pi pi-times" className="p-button-success" onClick={()=>setCancelAndReset(false)} />
            <Button label="OUI" type="button" disabled={spin} icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleAffectationDeletion} />
        </React.Fragment>
    )

    const cancelAffectationFooter =(

        <React.Fragment>
            <Button label="Retour" icon="pi pi-times" className="p-button-text" onClick={()=>setMessageContainer({...messageContainer,dialog:false})} />
            {/* <Button label="OUI" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleAffectationDeletion} /> */}
        </React.Fragment>
        
    );

    

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">*{errors[name].message}</small>
    };

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" title='editer' icon="pi pi-pencil"  className="p-button-warning" style={{marginRight: '.5em'}} onClick={()=>handleModification(rowData)}/>
                <Button type="button" title='supprimer' icon="pi pi-trash" className="p-button-danger" onClick={()=>handleAffectationDeletion(rowData)} />
            </React.Fragment>
        );
    }

    const deleteSpecialiteFooter =(
        <React.Fragment>
            <Button label="NON" disabled={spin?true:false} icon="pi pi-times" className="p-button-success" onClick={()=>setDialog({...dialog,delete:false})} />
            <Button label="OUI" disabled={spin?true:false} icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleAffectationDeletion} />
        </React.Fragment>
    )
    const handleAjouter = ()=>{
        reset();
        setDialog({...dialog,add:true});
        let anneeEnCours = listAnnee.filter(annee=>annee.isEnCours)[0];
        setValue("anneeAcademique",anneeEnCours);
        setHeureSuperieur(false);
    }

    const itemEnseignantTemplate = rowData=>{
        if(listEnseignant.length!==0){
            return listEnseignant.map(enseignant=>{
                if(enseignant.idEnseignant === rowData.idEnseignant){
                    return (
                        <div key={enseignant.matriculeEnseignant}>
                            <span>{enseignant.matriculeEnseignant} </span>
                            <span>{enseignant.nomEnseignant} </span>
                            <span>{enseignant.prenomEnseignant} </span>
                        </div>
                    );
                }
                return null
            })
        }
    }
    const saveDataFooter=(
        <React.Fragment>
            <div className='mt-3' style={{width:"100%", display:"flex", justifyContent:"flex-start"}}>
                {
                    dialog.modify? 
                    <React.Fragment>
                        <div className='ml-3'>
                            <Button type="submit" label="Enregistrer" 
                                icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} 
                                style={{marginRight:"15px"}} className="p-button-success"
                            />
                        </div>
                        <div className='ml-3'>
                            <Button disabled={spin?true:false} className=" p-button-danger" type="button" label="Quitter"  icon="pi pi-times"  onClick={()=>setDialog({...dialog,modify:false})}/>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className='ml-3'>
                            <Button type="submit" disabled={spin} className="p-button-info" label="Ajouter" icon="pi pi-plus" style={{marginRight:"15px"}} />
                        </div>
                        <div className='ml-3'>
                            <Button type="button" label="Enregistrer" 
                                disabled={spin||saveList.length===0?true:false}  icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} 
                                style={{marginRight:"15px"}} className="p-button-success" onClick={onSaveData} 
                            />
                        </div>
                        <div className='ml-3'>
                            <Button type="button" label="Quitter" 
                                disabled={spin?true:false} className="p-button-danger" icon="pi pi-times" 
                                onClick={()=>{ if(saveList.length !==0 && dialog.add){setCancelAndReset(true)}else{onHide()}}}
                            />
                        </div>
                    </React.Fragment>
                } 
                   
                
            </div>
        </React.Fragment>
        
        )
    const itemTemplate = (item)=>{
        return <span>{item.libelleCohorte}</span>
    }
    const columnBodyTemplate = (rowData)=>{
        return (
            <div>
                <span>{rowData.nomEnseignant} </span>
                <span>{rowData.prenomEnseignant} </span>
            </div>
        )
    }

    const responsabiliteTemplate = (rowData)=>{
        let responsabilite = rowData.enseignantResponsabilites.find(data=>data.statutEnseignantResponsabilite && data.responsabilitePriseEnCompte)
        return(
            <div>
                {responsabilite? <span>{responsabilite.libelleResponsabilite}</span>:<span>Néant</span>} 
            </div>
        )
    }

    const header = (
        isDepartement ? 
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={handleAjouter} />
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
        :
        isDA ?
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={handleAjouter} />
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
        :
        isDirecteur ?
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
        :
        isVp ?
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
        :
        ""
        
    );

    const handleSemestre = (parcours) =>{
        let semestre = getValues("semestre");
        let typeActivite = getValues("typeActivite");
        let elementConstitutif = getValues("elementConstitutif");
        if(semestre) parcoursUEHandler(parcours.idParcours,semestre);
        if(typeActivite) cohorteHandler(typeActivite);
        if(elementConstitutif) handleECSelectionner(elementConstitutif);
        if(parcours.idParcours){
                semestreService.get(parcours.idParcours).then(response=>{setListSemestre(response)}).catch(errors=>console.log(errors))
            }
    }

    const handleAnnee = (annee)=>{
        let activite = getValues("typeActivite");
        if(activite){
            cohorteHandler(activite);
        }
        
    }
    const handleEnseignant = ()=>{
        let enseignant = getValues("enseignant");
        if(enseignant){
            setEnseignantSelect({...enseignantSelect,popup:true, enseignant});
        }
        
    }
    const onHideEnseignant = ()=>{
        setEnseignantSelect({...enseignantSelect, popup:false})
    }

    const cohorteBody = rowData=>{
        return(
            <span title={rowData.libelleCohorte}>{rowData.libelleCohorte.substring(0,9)} ...</span>
        )
    }

       
    return(
        <div>
           
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">

                {header}
                
                {
                loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                <div className="p-col-12 p-grid" style={{marginTop : 20}}>
                    {isDepartement ?
                    <>
                    <div className="p-grid">
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee"  value={annee} options={listAnnee}  placeholder="Choisir une année"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onParcoursChange(e, "parcoursGet")}} optionLabel="libelleParcours"  value={parcours} options={listParcours}  placeholder="Choisir un parcours"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onNiveauChange(e, "niveau")}}  optionLabel="libelleNiveau"  value={niveau} options={listNiveau}  placeholder="Choisir un niveau"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onTypeActiviteChange(e, "typeActivite")}} optionLabel="libelleTypeActivite"  value={typeActivite} options={listTypeActivite}  placeholder="Choisir un type d'activité"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onCohorteChange(e, "cohorte")}} optionLabel="libelleCohorte" value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onSemestreChange(e, "semestre")}} optionLabel="libelleSemestre" value={semestre} options={listSemestre}  placeholder="Choisir un semestre"/>
                        <Button style={{marginLeft : 10}} label="Lister" onClick={listeActCohorte} disabled={!cohorte.idCohorte || !semestre.idSemestre}/>
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable 
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                            rows={5}
                            selectionMode="single" filterMatchMode="contains"
                            paginator={true} responsive={true}
                            value={listAffectation}
                            globalFilter={globalFilter}
                            header={cohorte ? `Liste des ECUE Affectées à ${cohorte.libelleCohorte} (${listAffectation.length})` : `Liste des ECUE Affectées  (${listAffectation.length})`}
                        >
                            <Column field="libelleElementConstitutif" header="EC" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Elémént Constitutif"  sortable filter filterMatchMode="contains"/>
                            <Column field="nbHeure" header="Nombre d'heures" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="nb. heures"  sortable filter filterMatchMode="contains"/>
                            <Column body={itemEnseignantTemplate} header="Enseignant" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Enseignant"  sortable filter filterMatchMode="contains"/>
                            <Column header={actionHeader} body={actionBodyTemplate} style={{width:"160px"}} />
                        </DataTable>
                    </div>
                    </>
                    :
                    isDA ?
                    <>
                       <div className="p-grid">
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee"  value={annee} options={listAnnee}  placeholder="Choisir une année"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onDepartementChange(e, "departement")}} optionLabel="lbDepartementEtab"  value={departement} options={listDepartement}  placeholder="Choisir un departement"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onParcoursChange(e, "parcoursGet")}} optionLabel="libelleParcours"  value={parcours} options={listParcours} filter  placeholder="Choisir un parcours"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onNiveauChange(e, "niveau")}}  optionLabel="libelleNiveau"  value={niveau} options={listNiveau}  placeholder="Choisir un niveau"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onTypeActiviteChange(e, "typeActivite")}} optionLabel="libelleTypeActivite"  value={typeActivite} options={listTypeActivite}  placeholder="Choisir un type d'activité"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onCohorteChange(e, "cohorte")}} optionLabel="libelleCohorte" value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onSemestreChange(e, "semestre")}} optionLabel="libelleSemestre" value={semestre} options={listSemestre}  placeholder="Choisir un semestre"/>
                        <Button style={{marginLeft : 10}} label="Lister" onClick={listeActCohorte} />
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable 
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                            rows={5}
                            selectionMode="single" filterMatchMode="contains"
                            paginator={true} responsive={true}
                            value={listAffectation}
                            globalFilter={globalFilter}
                            header={cohorte ? `Liste des ECUE Affectées à ${cohorte.libelleCohorte} ` : `Liste des ECUE Affectées`}
                        >
                            <Column field="libelleElementConstitutif" header="EC" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Elémént Constitutif"  sortable filter filterMatchMode="contains"/>
                            <Column field="nbHeure" header="Nombre d'heures" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="nb. heures"  sortable filter filterMatchMode="contains"/>
                            <Column body={itemEnseignantTemplate} header="Enseignant" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Enseignant"  sortable filter filterMatchMode="contains"/>
                            <Column header={actionHeader} body={actionBodyTemplate} style={{width:"160px"}} />
                        </DataTable>
                    </div> 
                    </>
                    :
                    isDirecteur ?
                    <>
                    <div className="p-grid">
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee"  value={annee} options={listAnnee}  placeholder="Choisir une année"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onDepartementChange(e, "departement")}} optionLabel="lbDepartementEtab"  value={departement} options={listDepartement}  placeholder="Choisir un departement"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onParcoursChange(e, "parcoursGet")}} optionLabel="libelleParcours"  value={parcours} options={listParcours}  placeholder="Choisir un parcours"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onNiveauChange(e, "niveau")}}  optionLabel="libelleNiveau"  value={niveau} options={listNiveau}  placeholder="Choisir un niveau"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onTypeActiviteChange(e, "typeActivite")}} optionLabel="libelleTypeActivite"  value={typeActivite} options={listTypeActivite}  placeholder="Choisir un type d'activité"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onCohorteChange(e, "cohorte")}} optionLabel="libelleCohorte" value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onSemestreChange(e, "semestre")}} optionLabel="libelleSemestre" value={semestre} options={listSemestre}  placeholder="Choisir un semestre"/>
                        <Button style={{marginLeft : 10}} label="Lister" onClick={listeActCohorte} />
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable 
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                            rows={5}
                            selectionMode="single" filterMatchMode="contains"
                            paginator={true} responsive={true}
                            value={listAffectation}
                            globalFilter={globalFilter}
                            header={cohorte ? `Liste des ECUE Affectées à ${cohorte.libelleCohorte} ` : `Liste des ECUE Affectées`}
                        >
                            <Column field="libelleElementConstitutif" header="EC" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Elémént Constitutif"  sortable filter filterMatchMode="contains"/>
                            <Column field="nbHeure" header="Nombre d'heures" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="nb. heures"  sortable filter filterMatchMode="contains"/>
                            <Column body={itemEnseignantTemplate} header="Enseignant" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Enseignant"  sortable filter filterMatchMode="contains"/>
                        </DataTable>
                    </div> 
                    </>
                    :
                    isVp ?
                    <>
                    <div className="p-grid">
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee"  value={annee} options={listAnnee}  placeholder="Choisir une année"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onEtablissementChange(e, "etablissement")}} optionLabel="sigleEtablissement"  value={etablissement} options={listEtablissement}  placeholder="Choisir un établissement"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onDepartementChange(e, "departement")}} optionLabel="lbDepartementEtab"  value={departement} options={listDepartement}  placeholder="Choisir un departement"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onParcoursChange(e, "parcoursGet")}} optionLabel="libelleParcours"  value={parcours} options={listParcours}  placeholder="Choisir un parcours"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onNiveauChange(e, "niveau")}}  optionLabel="libelleNiveau"  value={niveau} options={listNiveau}  placeholder="Choisir un niveau"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onTypeActiviteChange(e, "typeActivite")}} optionLabel="libelleTypeActivite"  value={typeActivite} options={listTypeActivite}  placeholder="Choisir un type d'activité"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onCohorteChange(e, "cohorte")}} optionLabel="libelleCohorte" value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onSemestreChange(e, "semestre")}} optionLabel="libelleSemestre" value={semestre} options={listSemestre}  placeholder="Choisir un semestre"/>
                        <Button style={{marginLeft : 10}} label="Lister" onClick={listeActCohorte} />
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable 
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                            rows={5}
                            selectionMode="single" filterMatchMode="contains"
                            paginator={true} responsive={true}
                            value={listAffectation}
                            globalFilter={globalFilter}
                            
                            header= {cohorte ? `Liste des ECUE Affectées à ${cohorte.libelleCohorte} ` : `Liste des ECUE Affectées`}
                        >
                            <Column field="libelleElementConstitutif" header="EC" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Elémént Constitutif"  sortable filter filterMatchMode="contains"/>
                            <Column field="nbHeure" header="Nombre d'heures" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="nb. heures"  sortable filter filterMatchMode="contains"/>
                            <Column body={itemEnseignantTemplate} header="Enseignant" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Enseignant"  sortable filter filterMatchMode="contains"/>
                        </DataTable>
                    </div> 
                    </>
                    :
                    ""
                    }
                 </div>

                }
            </div>

            <Dialog className="card" visible={dialog.add || dialog.modify}  style={{width:"100vw",height:"100vh"}} onHide={onHide} >
                <div className="p-grid">
                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                        <div className="p-d-flex p-jc-center">
                            <hr /><h5 className="text-header">Fiche de répartition des heures des EC</h5><hr />
                            <form className="p-fluid" onSubmit={dialog.add?handleSubmit(onSubmit):handleSubmit(onSaveData)}>
                                {isDepartement ?
                                <div>
                                    <div className="p-grid">
                                        <div  className="p-col-7 p-field" >
                                            <label htmlFor="">Année Académique {getFormErrorMessage('anneeAcademique')}</label>
                                            <Controller
                                                control={control}
                                                defaultValue={getValues("anneeAcademique")}
                                                name="anneeAcademique"
                                                rules={{ required: 'champ obligatoire.'}}
                                                render={({field})=>{
                                                    return(
                                                        <Dropdown
                                                            options={listAnnee} optionLabel="libelleAnnee"
                                                            name={field.name} id={field.name} value={field.value}
                                                            {...field}
                                                            onChange={(e)=>{ handleAnnee(e.value);field.onChange(e.value);}}
                                                            
                                                        />
                                                    )
                                                }}
                                            
                                            />
                                        </div>
                                        
                                        <div className="p-col-5 p-field">
                                            <label htmlFor="">Parcours {getFormErrorMessage('parcours')}</label>
                                            <Controller
                                                control={control} name="parcours"
                                                rules={{ required: 'champ obligatoire.'}}
                                                render={({field})=>{
                                                    return(
                                                        <Dropdown
                                                            filter
                                                            options={listParcours} optionLabel="libelleParcours"
                                                            name={field.name} id={field.name} value={field.value} {...field}
                                                            onChange={(e)=>{
                                                                let curentParcours = e.value
                                                                handleSemestre(curentParcours);field.onChange(curentParcours);}}
                                                        />
                                                    )
                                                }}
                                            
                                            />
                                        </div>
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="">Semestre {getFormErrorMessage('semestre')}</label>
                                        <Controller
                                            control={control}
                                            name="semestre"
                                            rules={{ required: 'champ obligatoire.'}}
                                            render={({field})=>{
                                                return(
                                                    <Dropdown
                                                        filter
                                                        options={listSemestre} optionLabel="libelleSemestre"
                                                        name={field.name} id={field.name} value={field.value}
                                                        {...field}
                                                        onChange = {(e)=>{
                                                            parcoursUEHandler(getValues("parcours").idParcours,e.value);
                                                            field.onChange(e.value);
                                                        }}
                                                    />
                                                )
                                            }}
                                        
                                        />
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6 p-field">
                                            <label htmlFor="">Elèment Constitutif {getFormErrorMessage('elementConstitutif')}</label>
                                            <Controller
                                                control={control}
                                                name="elementConstitutif" 
                                                rules={{ required: 'champ obligatoire.'}}
                                                render={({field})=>{
                                                    return(
                                                        <Dropdown
                                                            filter
                                                            options={listEC} optionLabel="libelleElementConstitutif"
                                                            name={field.name} id={field.name} value={field.value}
                                                            {...field}
                                                            onChange={e=>{field.onChange(e.value);handleECSelectionner(e.value)}}
                                                        />
                                                    )
                                                }}
                                            
                                            />
                                        </div>
                                        <div className="p-col-6 p-field">
                                            <label htmlFor="">Type Activité {getFormErrorMessage('typeActivite')}</label>
                                            <Controller
                                                control={control}
                                                name="typeActivite"
                                                rules={{ required: 'champ obligatoire.'}}
                                                render={({field})=>{
                                                    return(
                                                        <Dropdown
                                                            options={listTypeActivite} optionLabel="libelleTypeActivite"
                                                            name={field.name} id={field.name} value={field.value}
                                                            {...field}
                                                            onChange={e=>{cohorteHandler(e.value);field.onChange(e.value)}}
                                                        />
                                                        )
                                                    }}
                                                />
                                        </div>
                                    </div>
                                    
                                    <div className="p-grid">
                                        <div className="p-col-6 p-field">
                                            <div className="p-float">
                                                <div>
                                                    <label htmlFor="">Enseignant {getFormErrorMessage('enseignant')}</label>
                                                        <Controller
                                                            control={control} name="enseignant"
                                                            rules={{ required: 'champ obligatoire.'}}
                                                            render={({field})=>{
                                                                return(
                                                                    <Dropdown
                                                                        name={field.name} id={field.name} value={field.value}
                                                                        itemTemplate={itemEnseignantTemplate} 
                                                                        options={listEnseignant} optionLabel="nomPrenomEnseignant"
                                                                        filter filterBy="nomPrenomEnseignant"
                                                                        {...field}
                                                                        onChange={e=>{setEnseignantSelect({...enseignantSelect, selected:true});field.onChange(e.value)}}
                                                                    />
                                                                )
                                                            }}
                                                        
                                                        />
                                                </div>
                                                {
                                                    enseignantSelect.selected && (
                                                        <div style={{marginLeft:"10px"}}>
                                                            <Button type="button" icon="pi pi-list" className="p-button-info" onClick={handleEnseignant}/>
                                                        </div>
                                                    )
                                                }
                                                
                                            </div>
                                        </div>
                                        <div className="p-col-6 p-field">
                                        <span>
                                            <label htmlFor="">Nombre d'Heures(h) {getFormErrorMessage('nbHeure')}</label>
                                                <Controller
                                                    control={control}
                                                    name="nbHeure"
                                                    rules={{ required: 'champ obligatoire.'}}
                                                    render={({field})=>{
                                                        return(
                                                            <InputText
                                                                keyfilter="num"
                                                                name={field.name} id={field.name} value={field.value}
                                                                {...field}
                                                                onChange={(e)=>{field.onChange(e.currentTarget.value);setHeureSuperieur(false);}}
                                                            />
                                                        )
                                                    }}
                                                />
                                        </span>
                                        {noAvailable && <span style={{color:"red"}}>Cet EC ne disponse pas d'heure de TP/TD.</span>}
                                        {heureSuperieur && <span style={{color:"red"}}>Invalide, Nombre d'heures supérieur à l'EC selectionnée !</span>}
                                        </div>
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="cohorte">Cohorte/classe(s) Concernée(s) {getFormErrorMessage('cohorte')}</label>
                                        <Controller
                                            control={control}
                                            name="cohorte"
                                            render = {({field})=>(
                                                <PickList 
                                                    source={listTypeActiviteCohorte} target={target} itemTemplate={itemTemplate}
                                                    name={field.name} id={field.name}
                                                    responsive
                                                    {...field}
                                                    onChange={e=>{field.onChange(e);pickListHanlder(e)}}
                                                />
                                            )}
                                        />
                                        {pickListChecker && <span style={{color:"red"}}>Vous devez selectionner au moins une cohorte.</span> }
                                        
                                    </div>
                                </div>
                                :
                                isDA ?
                                <div>
                                    <div className="p-grid">
                                        <div  className="p-col-12 p-field" >
                                            <label htmlFor="">Année Académique {getFormErrorMessage('anneeAcademique')}</label>
                                            <Controller
                                                control={control}
                                                defaultValue={getValues("anneeAcademique")}
                                                name="anneeAcademique"
                                                rules={{ required: 'champ obligatoire.'}}
                                                render={({field})=>{
                                                    return(
                                                        <Dropdown
                                                            options={listAnnee} optionLabel="libelleAnnee"
                                                            name={field.name} id={field.name} value={field.value}
                                                            onChange={(e)=>{
                                                                field.onChange(e.value);
                                                                setListTypeActiviteCohorte([])}}
                                                            {...field}
                                                        />
                                                    )
                                                }}
                                            
                                            />
                                        </div>
                                        
                                        <div className="p-col-6 p-field">
                                            <label htmlFor="">Département {getFormErrorMessage('departement')}</label>
                                            <Controller
                                                control={control} name="departement"
                                                rules={{ required: 'champ obligatoire.'}}
                                                render={({field})=>{
                                                    return(
                                                        <Dropdown
                                                            options={listDepartement} optionLabel="lbDepartementEtab"
                                                            name={field.name} id={field.name} value={field.value} {...field}
                                                            onChange={(e)=>{field.onChange(e.value);onDepartementChange(e, "departement")}}
                                                        />
                                                    )
                                                }}
                                            
                                            />
                                        </div>
                                        
                                        <div className="p-col-6 p-field">
                                            <label htmlFor="">Parcours {getFormErrorMessage('parcours')}</label>
                                            <Controller
                                                control={control} name="parcours"
                                                rules={{ required: 'champ obligatoire.'}}
                                                render={({field})=>{
                                                    return(
                                                        <Dropdown
                                                            options={listParcours} optionLabel="libelleParcours"
                                                            name={field.name} id={field.name} value={field.value} {...field}
                                                            onChange={(e)=>{
                                                                handleSemestre(e.value);field.onChange(e.value);}}
                                                        />
                                                    )
                                                }}
                                            
                                            />
                                        </div>
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="">Semestre {getFormErrorMessage('semestre')}</label>
                                        <Controller
                                            control={control}
                                            name="semestre"
                                            rules={{ required: 'champ obligatoire.'}}
                                            render={({field})=>{
                                                return(
                                                    <Dropdown
                                                        options={listSemestre} optionLabel="libelleSemestre"
                                                        name={field.name} id={field.name} value={field.value}
                                                        {...field}
                                                        onChange = {(e)=>{
                                                            parcoursUEHandler(getValues("parcours").idParcours,e.value);
                                                            field.onChange(e.value);
                                                        }}
                                                    />
                                                )
                                            }}
                                        
                                        />
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6 p-field">
                                            <label htmlFor="">Elèment Constitutif {getFormErrorMessage('elementConstitutif')}</label>
                                            <Controller
                                                control={control}
                                                name="elementConstitutif" 
                                                rules={{ required: 'champ obligatoire.'}}
                                                render={({field})=>{
                                                    return(
                                                        <Dropdown
                                                            options={listEC} optionLabel="libelleElementConstitutif"
                                                            name={field.name} id={field.name} value={field.value}
                                                            {...field}
                                                            onChange={e=>{field.onChange(e.value);handleECSelectionner(e.value)}}
                                                        />
                                                    )
                                                }}
                                            
                                            />
                                        </div>
                                        <div className="p-col-6 p-field">
                                            <label htmlFor="">Type Activité {getFormErrorMessage('typeActivite')}</label>
                                            <Controller
                                                control={control}
                                                name="typeActivite"
                                                rules={{ required: 'champ obligatoire.'}}
                                                render={({field})=>{
                                                    return(
                                                        <Dropdown
                                                            options={listTypeActivite} optionLabel="libelleTypeActivite"
                                                            name={field.name} id={field.name} value={field.value}
                                                            {...field}
                                                            onChange={e=>{cohorteHandler(e.value);field.onChange(e.value)}}
                                                        />
                                                        )
                                                    }}
                                                />
                                        </div>
                                    </div>
                                    
                                    <div className="p-grid">
                                    <div className="p-col-6 p-field">
                                        <div className="p-float">
                                            <div>
                                                <label htmlFor="">Enseignant {getFormErrorMessage('enseignant')}</label>
                                                    <Controller
                                                        control={control} name="enseignant"
                                                        rules={{ required: 'champ obligatoire.'}}
                                                        render={({field})=>{
                                                            return(
                                                                <Dropdown
                                                                    name={field.name} id={field.name} value={field.value}
                                                                    itemTemplate={itemEnseignantTemplate} 
                                                                    options={listEnseignant} optionLabel="nomPrenomEnseignant"
                                                                    filter filterBy="nomPrenomEnseignant"
                                                                    {...field}
                                                                    onChange={e=>{setEnseignantSelect({...enseignantSelect, selected:true});field.onChange(e.value)}}
                                                                />
                                                            )
                                                        }}
                                                    
                                                    />
                                            </div>
                                            {
                                                enseignantSelect.selected && (
                                                    <div style={{marginLeft:"10px"}}>
                                                        <Button type="button" icon="pi pi-list" className="p-button-info" onClick={handleEnseignant}/>
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="p-col-6 p-field">
                                        <span>
                                            <label htmlFor="">Nombre d'Heures(h) {getFormErrorMessage('nbHeure')}</label>
                                                <Controller
                                                    control={control}
                                                    name="nbHeure"
                                                    rules={{ required: 'champ obligatoire.'}}
                                                    render={({field})=>{
                                                        return(
                                                            <InputText
                                                                keyfilter="num"
                                                                name={field.name} id={field.name} value={field.value}
                                                                {...field}
                                                                onChange={(e)=>{field.onChange(e.currentTarget.value);setHeureSuperieur(false);}}
                                                            />
                                                        )
                                                    }}
                                                />
                                        </span>
                                        {noAvailable && <span style={{color:"red"}}>Cet EC ne disponse pas d'heure de TP/TD.</span>}
                                        {heureSuperieur && <span style={{color:"red"}}>Invalide, Nombre d'heures supérieur à l'EC selectionnée !</span>}
                                        </div>
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="cohorte">Cohorte/classe(s) Concernée(s) {getFormErrorMessage('cohorte')}</label>
                                        <Controller
                                            control={control}
                                            name="cohorte"
                                            rules={{ required: 'champ obligatoire.'}}
                                            render = {({field})=>(
                                                <PickList 
                                                    source={listTypeActiviteCohorte} target={target} itemTemplate={itemTemplate}
                                                    name={field.name} id={field.name}
                                                    responsive
                                                    {...field}
                                                    onChange={e=>{field.onChange(e);pickListHanlder(e)}}
                                                />
                                            )}
                                        />
                                        {pickListChecker && <span style={{color:"red"}}>Vous devez selectionner au moins une cohorte.</span> }
                                        
                                    </div>
                                </div>
                                :
                                ""
                                }
                                {
                                    saveDataFooter
                                }
                            </form>
                            
                        </div>
                    </div>

                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12 mb-3">
                        <div style={{marginBottom:"15px"}}>
                            <div className=" p-fieldset">

                                <DataTable 
                                    rows={5} filterMatchMode="contains"  responsive
                                    value={ecSelectionnee}
                                    header="Informations sur  EC Selectionné"
                                >
                                    <Column field="libelleEC" header="Elément Constitutif" headerStyle={{textTransform:"capitalize"}}/>
                                    <Column field="restantHeureCours" header="Cours Magistral" headerStyle={{textTransform:"capitalize"}}/>
                                    <Column field="restantHeureTD" header="Travaux Dirigés" headerStyle={{textTransform:"capitalize"}}/>
                                    <Column field="restantHeureTP" header="Travaux Pratiques" headerStyle={{textTransform:"capitalize"}}/>
                                </DataTable>
                            </div>
                        </div>
                        {
                            dialog.add &&(
                            <div>
                                <hr /><h5 className="text-header">Nouvellement ajouté </h5><hr />
                                <div className=" p-fieldset">
                                    <table className="table" style={{minHeight:"300px"}}>
                                        <thead>
                                            <tr className="p-grid">
                                                {/* <th className="p-col-2">Semestre</th> */}
                                                <th className="p-col-2">Cohorte/Classe</th>
                                                <th className="p-col-2" >EC</th>
                                                <th className="p-col-2" >Activité</th>
                                                <th className="p-col-2" >Temps</th>
                                                <th className="p-col-2" >Enseignant</th>
                                                <th className="p-col-2" >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                saveList.map((spec,index) =>
                                                    <tr className="p-grid" key={index+1}>
                                                        {/* <td className="p-col-2">{spec.semestre.codeSemestre}</td> */}
                                                        <td className="p-col-2" title={spec.cohorte.libelleCohorte}>{spec.cohorte.libelleCohorte.substring(0, 9)} ...</td>
                                                        <td className="p-col-2">{spec.elementConstitutif.libelleElementConstitutif}</td>
                                                        <td className="p-col-2">{spec.typeActivite.libelleTypeActivite}</td>
                                                        <td className="p-col-2">{spec.nbHeure}h</td>
                                                        <td className="p-col-2">{spec.enseignant.nomPrenomEnseignant}</td>
                                                        <td><Button icon="pi pi-trash" title='supprimer' className="p-button-danger p-col-2" onClick={() => {removeItem(index)}}/></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>      
                                    </table>
                                </div>
                            </div>
                            )
                        }
                        
                    </div>
                </div>
            </Dialog>

            <Dialog visible={messageContainer.dialog} style={{ width: '450px' }} header="Confirmez" modal footer={cancelAffectationFooter} onHide={()=>setMessageContainer({...messageContainer,dialog:false})}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>
                        Le nombre d'heure disponible pour l'élement Constitutif <strong>{messageContainer.elementConstitutif} </strong> dans le type activité <strong>{messageContainer.typeActivite} </strong> est supérieur à <strong>{messageContainer.heureSupp} </strong> pour le <strong>{messageContainer.libelleCohorte}</strong>.
                    </span>
                </div>
            </Dialog>

            <Dialog visible={cancelAndReset} style={{ width: '450px' }} header="Confirmez" modal footer={cancelResetFooter} onHide={()=>setCancelAndReset(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>Etes-vous sûr de quitter ?</span><br /><br />
                    <span>Les informations saisies seront perdues</span>
                </div>
            </Dialog>

            <Dialog visible={dialog.delete} style={{ width: '450px' }} header="Confirmez" modal footer={deleteSpecialiteFooter} onHide={()=>setDialog({...dialog,delete:false})}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {"'this.state.deletionId'" && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                </div>
            </Dialog>
            <Dialog visible={enseignantSelect.popup} style={{height:"400px"}} header="Confirmez" modal onHide={onHideEnseignant}>
                <DataTable 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    rows={5} selectionMode="single" filterMatchMode="contains"
                    paginator={true} responsive={true} value={[enseignantSelect.enseignant]}
                    header="Enseignant Selectionné" >

                    <Column field="matriculeEnseignant" header="matricule" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    {enseignantSelect.selected && (
                    <Column body={columnBodyTemplate} header="nom" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    )}
                    <Column field="emailEnseignant" header="email" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column field="telEnseignant" header="telephone" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column field='grade.libelleGrade' header="grade"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/> 
                    <Column field='statutEnseignant.libelleStatutEnseignant' header="Statut"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/> 
                    {enseignantSelect.selected && (
                    <Column body={responsabiliteTemplate} header="responsabilité"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    )}
                    <Column field="specialite.libelleDiscipline" header="Discipline"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column field="specialite.libelleSpecialite" header="Spécialité"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column field="nomIes" header="université"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column field="lbDepartementEtab" header="département"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                </DataTable>
            </Dialog>
        </div>
    )
}
