import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown'

import { TypeCategoriePrixService } from '../../service/TypeCategoriePrixService';
import { EditionService } from '../../service/EditionService';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InputTextarea } from 'primereact/inputtextarea';

function Edition() {
    let emptyEdition = {
            id: null,
            annee: null,
            numero: null,
            manuelProcedures: ""
        };
          
    const [annees, setAnnees] = useState([]);
    const [editions, setEditions] = useState([]);
    const [editionDialog, setEditionDialog] = useState(false);
    const [deleteEditionDialog, setDeleteEditionDialog] = useState(false);
    const [edition, setEdition] = useState(emptyEdition);
    const [selectedEdtions, setSelectedEditions] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const dt = useRef(null);
    const editionService = new EditionService();
    const notify = new Notify();
    const spinner = new Spinner();

    useEffect(() => {
                            
        setLoading(true)
        editionService.getAll().then(data => {
            setEditions(data)
            setLoading(false);
            //notify.success();
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
            notify.echec(error.response.data.message);
        })

        let listeAnnee = []
        let today = new Date();
        let year = today.getFullYear();
              
        for(let i = 0; i < 100; i++){
            listeAnnee.push({id : year-14-i, annee : year-14-i })
        }
        setAnnees(listeAnnee)
    }, []);
    const openNew = () => {
        setEdition(emptyEdition);
        setSubmitted(false);
        setEditionDialog(true);
    }
                    
    const hideDialog = () => {
        setSubmitted(false);
        setEditionDialog(false);
    }
                    
    const hideDeleteEditionDialog = () => {
        setDeleteEditionDialog(false);
    }
    const saveEdition = () => {
        setSubmitted(true);

        if (edition.annee.trim()) {
            let _edition = {...edition};
            
            if (edition.id) {
                editionService.update(edition.id, _edition).then(data => {
                    console.log(data);
                    editionService.getAll().then(data => setEditions(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
            }
            else {
                editionService.create(_edition).then(data => {
                    console.log(data);
                    editionService.getAll().then(data => setEditions(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
                
            }

            setEditionDialog(false);
            setEdition(emptyEdition);
        }
    }
    const editEdition = (edition) => {
        setEdition({...edition});
        setEditionDialog(true);
    }

    const confirmDeleteEdition = (edition) => {
        setEdition(edition);
        setDeleteEditionDialog(true);
    }

    const deleteEdition = () => {
        editionService.delete(edition.id).then(data => {
            setEdition(emptyEdition);
            console.log(data);
            editionService.getAll().then(data => setEditions(data));
            notify.success();
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        })
        setDeleteEditionDialog(false);
        
    }
    const onChampsChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _edition = {...edition};
        _edition[`${name}`] = val;
          
        setEdition(_edition);
    }
          
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editEdition(rowData)} />
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteEdition(rowData)} style={{marginLeft : 5}} />
            </React.Fragment>
        );
    }
    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </span>
        </div>
    );
    const editionDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveEdition} />
        </React.Fragment>
    );
    const deleteEditionDialogFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEditionDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteEdition} />
        </React.Fragment>
    );
    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

  return (
   <div className="datatable-crud-demo">
        <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <div className="card">
                        
            {header}
            {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :
                    <DataTable ref={dt} value={editions} selection={selectedEdtions} onSelectionChange={(e) => setSelectedEditions(e.value)}
                        dataKey="id" paginator rows={10} 
                        globalFilter={globalFilter}
                        header="Liste des éditions des prix d'excellence">
        
                        <Column field="annee" header="Année" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="numero" header="Numéro" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="manuelProcedures" header="Manuel de procédures" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
                    </DataTable>
            }
        </div>
        <Dialog visible={editionDialog} style={{ width: '450px' }} header="Informations Edition" modal className="p-fluid" footer={editionDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                        <label htmlFor="annee">Année</label>
                        <Dropdown optionLabel="annee" value={edition.annee} options={annees} onChange={(e) => onChampsChange(e, 'annee')} required  />
                        {/*submitted && !categoriePrix.typeCategoriePrix.id && <small className="p-error">Ce champs est obligatoire.</small>*/}
                </div>
                <div className="p-field">
                    <label htmlFor="numero">Numéro</label>
                    <InputText id="numero" value={edition.numero} onChange={(e) => onChampsChange(e, 'numero')} required className={classNames({ 'p-invalid': submitted && !edition.numero })} />
                    {submitted && !edition.numero && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="description">Manuel de procédures</label>
                    <InputTextarea id="description" value={edition.manuelProcedures} onChange={(e) => onChampsChange(e, 'manuelProdedures')}  />
                </div>
        </Dialog>
        <Dialog visible={deleteEditionDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteEditionDialogFooter} onHide={hideDeleteEditionDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                {edition && <span>Etes vous sûr de supprimer cet élément ? <b>{edition.annee}</b>?</span>}
            </div>
        </Dialog>
    </div>
  )
}

export default Edition