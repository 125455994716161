import React, { useEffect, useState } from "react"
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Spinner } from "../../components/Spinner";
import { Notify } from "../../components/Notify";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import classNames from "classnames";
import { CohorteServices } from "../../volumeHoraire/service/CohorteServices";
import { AnneeService } from "../service/AnneeService";
import { ParcoursFiliereService } from "../service/ParcoursFiliereService";
import { SessionExamenService } from "../service/SessionExamenService";
import { ToastContainer } from "react-toastify";
import { NotationService } from "../service/NotationService";


function NoteEtudiant() {

  
  let sessionReclamations 
  const actionHeader = <Button type="button" icon="pi pi-cog" />;

  const cohorteServices = new CohorteServices();
  const anneeService = new AnneeService();
  const sessionService = new SessionExamenService();
  const notationservice = new NotationService();
  const notify = new Notify();
  const spinner = new Spinner()



  const defaultValues = {  
    annee: null,
    departement: null,
    parcour: null,
    niveau: null,
    session: null,
    };



  const [token, setToken] = useState();
  const [etudiant, setEtudiant] = useState(null);
  const [semestres, setSemestres] = useState([]);
  const [semestre, setSemestre] = useState();
  const [parcour, setParcour] = useState();
  const [annee, setAnnee] = useState();
  const [sessions, setSessions] = useState([]);
  const [typeSessions, setTypeSessions] = useState([]);
  const [annees, setAnnees] = useState([]);
  const [typeSession, setTypeSession] = useState();
  const [listeECs, setListeECs] = useState([]);
  const [dialogeAfficheNote, setDialogeAfficheNote] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [noteExams, setNoteExams] = useState([]);
  const [selectIdEC, setSelectIdEC] = useState(null);
  const [note, setNote] = useState();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    idElementConstitutif: null,
    codeElementConstitutif: null,
    libelleElementConstitutif: null,
    creditElementConstitutif: null,
    notes: [
      {
        idExamen: null,
        idNotation: null,
        libelleExamen: null,
        dateExamen: null,
        note: null,
        observation: null
      }
    ]
  });
  



  const campusEtudiant = localStorage.getItem("campusEtudiant");
  const isEtudiant =  parseInt(JSON.parse(campusEtudiant))
  const campusScolarite = localStorage.getItem("campusScolarite");
  const isScolarite = parseInt(JSON.parse(campusScolarite));


  const onChangeSemestre = (e) => {
    let val = (e.target && e.target.value) || "";
    setSemestre(val);
    
  }

  const onChangeAnnee = (e) => {
    let val = (e.target && e.target.value || "" )
      setAnnee(val)
  }

// const listSemestreNote = () => {
//     let idParcoursFiliere;
//     let idAnneeAcademique;
//     let idSession = typeSession.idTypeSession;
//     let idSemestre = semestre.idSemestre;

    
//     let idEC = semestre.idElementConstitutif
//     if (isEtudiant) {
//         idParcoursFiliere = etudiant.idParcoursFiliere;
//         idAnneeAcademique = etudiant.idAnneeAcademique;
//     } 
   
// };

useEffect(() => {
    
    const tokenDecoded = localStorage.getItem("tokenDecoded");
    const token = JSON.parse(tokenDecoded);
    setToken(token);
    const ine = `${token.preferred_username[0].toUpperCase()}${token.preferred_username.slice(
            1
    )}`;

    anneeService.getAnneeAcademiqueForEtudiant(ine).then((data) => {
        setAnnees(data)
        
    })

    cohorteServices
        .getEtudiant(ine).then((data) => {
        console.log("Donnes etudiant :",data)
        setEtudiant(data);
        setSemestres(data.listeSemetreDto);
    });

    
   
   

}, []);




const AfficherEC = () =>{

    
    const idEtudiant = etudiant.idEtudiant
    const idSemestre = semestre.idSemestre
    const idAnneeAcademique = annee.idAnneeAcademique


    //console.log("les id :",idEtudiant,idAnneeAcademique, idSemestre );

    setLoading(true)
    notationservice.getElementConstitutifs(idEtudiant,idAnneeAcademique, idSemestre ).then((data) => {
        
       setListeECs(data)
       //console.log("EC et notes :",data)
       setLoading(false)
     })
     .catch((error) => {
        console.log(error);
        notify.echec(error.response.data.message);
    });

}




const NoteEC = (rowData) => {
    //console.log("Ligne :", rowData)

    //console.log("Info exam :",rowData.notes[0].libelleExamen)

    setDialogeAfficheNote(true);

     setData({
        idElementConstitutif: rowData.idElementConstitutif,
        codeElementConstitutif: rowData.codeElementConstitutif,
        libelleElementConstitutif: rowData.libelleElementConstitutif,
        creditElementConstitutif: rowData.creditElementConstitutif,
        notes: [
          {
            idExamen: rowData.notes[0].idExamen,
            idNotation: rowData.notes[0].idNotation,
            libelleExamen: rowData.notes[0].libelleExamen,
            dateExamen: rowData.notes[0].dateExamen,
            note: rowData.notes[0].note,
            observation: rowData.notes[0].observation
          }
        ]
      });


   
}

const hideAfficheNoteDialog = () => {
    setDialogeAfficheNote(false);
};


const AfficheNoteDialogFooter = (
    <React.Fragment>
        <Button
            label="Quitter"
            icon="pi pi-times"
            className="p-button-text"
            onClick={hideAfficheNoteDialog}
        />
        
    </React.Fragment>
);



const ActionBodyTemplateNote = (rowData) => {

    return (
        <React.Fragment> 
                    <Button
                    icon="pi pi-plus"
                    className="p-button-success p-mr-2"
                   
                    onClick={() => NoteEC(rowData)}                    
                    />
        </React.Fragment>
    )
    
}



  return (

    <div className="card card-w-title datatable-demo">

      <div className="p-grid p-lg-10">
        <div
            className="p-field"
            style={{ marginLeft: 10 }}
        >

            <Dropdown

            optionLabel="libelleAnnee"
            value={annee}
            options={annees}
            onChange={(e) => {
                onChangeAnnee(e);
            }}
            required
            className={classNames({
                "p-invalid": "this.state.submitted",
            })}
            placeholder="Selectionner une année"
            />

            <Dropdown
                optionLabel="libelleSemestre"
                value={semestre}
                options={semestres}
                onChange={(e) => {
                    onChangeSemestre(e);
                }}
                required
                className={classNames({
                    "p-invalid": "this.state.submitted",
                })}
                placeholder="Selectionner un semestre"
            />
            </div>

            <div className="p-field" style={{ marginLeft: 10 }}>
                <Button
                    style={{ marginLeft: "0%" }}
                    label="Afficher"
                    onClick={AfficherEC}
                /> 
            </div> 
        </div>
            
           
        
            

            {loading ? (
                 <div className="p-grid">
                 <div className="p-col-6"></div>
                 <div className="p-col-6">
                     {spinner.loadingSpinner(
                         "spin",
                         "black"
                     )}
                 </div>
             </div>
         ) 
            :
       
      <DataTable
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        rows={5}
        selectionMode="single"
        filterMatchMode="contains"
        paginator
        responsive
        value={listeECs}
        header="Liste des Elements Constitutifs"
        >
        
        <Column
            field="codeElementConstitutif"
            header="Code"
            headerStyle={{
                textTransform: "capitalize",
            }}
            filterPlaceholder="Search ..."
            sortable
            filter
        />

        <Column
            field="libelleElementConstitutif"
            header="Libellé"
            headerStyle={{
                textTransform: "capitalize",
            }}
            filterPlaceholder="Search ..."
            sortable
            filter
        />

        <Column
            field="creditElementConstitutif"
            header="Credit"
            headerStyle={{
                textTransform: "capitalize",
            }}
            filterPlaceholder="Search ..."
            sortable
            filter
           
        />

        
        
        <Column
            header={actionHeader}
            body={ActionBodyTemplateNote}
            style={{
                textAlign: "center",
                width: "8em",
            }}
        />

    </DataTable>
    }

    <Dialog
        visible={dialogeAfficheNote}
        style={{ width: "90%" }}
        header="Informations sur les notes de l'examen"
        modal
        className="p-fluid"
        footer={AfficheNoteDialogFooter}
        onHide={hideAfficheNoteDialog}
    >
        

        
        
        
        <div className="p-grid">
                <div className="p-col-8 p-md-8 p-lg-12">
                    <div className="p-grid">
                    
                        
                        <DataTable
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                            rows={5}
                            selectionMode="single"
                            filterMatchMode="contains"
                            paginator
                            responsive
                            value={data.notes}
                            header="Notes de l'examen de l'EC"
                            
                        >
                            <Column
                                field="dateExamen"
                                header="Date examen"
                                headerStyle={{
                                    textTransform: "capitalize",
                                }}
                                filterPlaceholder="Search ..."
                                sortable
                                //filter
                            />
                            <Column
                                field="libelleExamen"
                                header="Libelle examen"
                                headerStyle={{
                                    textTransform: "capitalize",
                                }}
                                filterPlaceholder="Search ..."
                                sortable
                                //filter
                            />
                            <Column
                                field="note"
                                header="Note"
                                headerStyle={{
                                    textTransform: "capitalize",
                                }}
                                filterPlaceholder="Search ..."
                                sortable
                                //filter
                            />
                           
                            
                        </DataTable>
                    
                    </div>
                    
                    
                </div>
                
            </div>
        </Dialog>

      
      </div>

    
  )
}

export default NoteEtudiant 