import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import { DomaineService } from '../../service/DomaineService';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';


function Domaine() {
  let emptyDomaine = {
                id: null,
                nom: '',
                description: null
            };
        
            const [domaines, setDomaines] = useState([]);
            const [domaineDialog, setDomaineDialog] = useState(false);
            const [deleteDomaineDialog, setDeleteDomaineDialog] = useState(false);
            const [domaine, setDomaine] = useState(emptyDomaine);
            const [selectedDomaines, setSelectedDomaines] = useState(null);
            const [submitted, setSubmitted] = useState(false);
            const [globalFilter, setGlobalFilter] = useState(null);
            const [loading, setLoading] = useState(false);
            const dt = useRef(null);
            const domaineService = new DomaineService();
            const notify = new Notify();
            const spinner = new Spinner();
        
        
            useEffect(() => {
                
                setLoading(true)
                domaineService.getAll().then(data => {
                    setDomaines(data)
                    setLoading(false);
                    //notify.success();
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                    notify.echec(error.response.data.message);
                })
  
            }, []);
        
            const openNew = () => {
                setDomaine(emptyDomaine);
                setSubmitted(false);
                setDomaineDialog(true);
            }
        
            const hideDialog = () => {
                setSubmitted(false);
                setDomaineDialog(false);
            }
        
            const hideDeleteDomaineDialog = () => {
                setDeleteDomaineDialog(false);
            }
        
            const saveDomaine = () => {
                setSubmitted(true);
        
                if (domaine.nom.trim()) {
                    let _domaine = {...domaine};
                    if (domaine.id) {
                      domaineService.update(domaine.id, _domaine).then(data => {
                            console.log(data);
                            domaineService.getAll().then(data => setDomaines(data));
                            notify.success();
                        })
                        .catch(error => {
                            console.log(error);
                            notify.echec(error.response.data.message);
                        })
                    }
                    else {
                      domaineService.create(_domaine).then(data => {
                            console.log(data);
                            domaineService.getAll().then(data => setDomaines(data));
                            notify.success();
                        })
                        .catch(error => {
                            console.log(error);
                            notify.echec(error.response.data.message);
                        })
                        
                    }
        
                    setDomaineDialog(false);
                    setDomaine(emptyDomaine);
                }
            }
            const editDomaine = (domaine) => {
                setDomaine({...domaine});
                setDomaineDialog(true);
            }
        
            const confirmDeleteDomaine = (domaine) => {
                setDomaine(domaine);
                setDomaineDialog(true);
            }
        
            const deleteDomaine = () => {
                domaineService.delete(domaine.id).then(data => {
                    setDomaine(emptyDomaine);
                    console.log(data);
                    domaineService.getAll().then(data => setDomaines(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
                setDeleteDomaineDialog(false);
                
            }
        
            const onChampsChange = (e, name) => {
                const val = (e.target && e.target.value).toUpperCase() || '';
                let _domaine = {...domaine};
                _domaine[`${name}`] = val;
        
                setDomaine(_domaine);
            }
        
            const actionBodyTemplate = (rowData) => {
                return (
                    <React.Fragment>
                        <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editDomaine(rowData)} />
                        <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteDomaine(rowData)} style={{marginLeft : 5}} />
                    </React.Fragment>
                );
            }
        
            const header = (
                <div className="table-header p-col-12">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                        <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                    </span>
                </div>
            );
            const domaineDialogFooter = (
                <React.Fragment>
                    <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                    <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveDomaine} />
                </React.Fragment>
            );
            const deleteDomaineDialogFooter = (
                <React.Fragment>
                    <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDomaineDialog} />
                    <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteDomaine} />
                </React.Fragment>
            );
            const actionHeader = <Button type="button" icon="pi pi-cog"/>;
  return (
    <div className="datatable-crud-demo">
    <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />

    <div className="card">
        
        {header}
        {loading ?
        <div className="p-grid">
            <div className="p-col-6" ></div>
            <div className="p-col-6" >
                {spinner.loadingSpinner("spin", "black")}
            </div>
        </div>
        :
        <DataTable ref={dt} value={domaines} selection={selectedDomaines} onSelectionChange={(e) => setSelectedDomaines(e.value)}
            dataKey="id" paginator rows={10} 
            globalFilter={globalFilter}
            header="Liste des domaines de compétition">

            <Column field="nom" header="Domaine" filterMatchMode="contains" sortable={true} filter={true}></Column>
            <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
        </DataTable>
        }
    </div>
    <Dialog visible={domaineDialog} style={{ width: '450px' }} header="Informations Domaine" modal className="p-fluid" footer={domaineDialogFooter} onHide={hideDialog}>
        <div className="p-field">
            <label htmlFor="libelleDomaine">Libellé domaine</label>
            <InputText id="libelleDomaine" value={domaine.nom} onChange={(e) => onChampsChange(e, 'nom')} required autoFocus className={classNames({ 'p-invalid': submitted && !domaine.libelleDomaine })} />
            {submitted && !domaine.nom && <small className="p-error">Ce champs est obligatoire.</small>}
        </div>
        <div className="p-field">
            <label htmlFor="description">Description</label>
            <InputTextarea id="description" value={domaine.description} onChange={(e) => onChampsChange(e, 'description')}  />
        </div>
    </Dialog>

    <Dialog visible={deleteDomaineDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteDomaineDialogFooter} onHide={hideDeleteDomaineDialog}>
        <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
            {domaine && <span>Etes vous sûr de supprimer cet élément ? <b>{domaine.libelleDomaine}</b>?</span>}
        </div>
    </Dialog>

</div>
  )
}

export default Domaine