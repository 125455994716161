import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "jspdf-autotable";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import TypeCandidature from "./parametres/TypeCandidature";

import { ToastContainer} from 'react-toastify';

function DepotPrix() {
  const actionHeader = <Button type="button" icon="pi pi-cog" />;

  const [depotPrixDialog, setDepotPrixDialog] = useState(false);
  const [documentLists, setDocumentLists] = useState([]);
  const [appelCandidatures, setAppelCandidatures] = useState([]);
  const [datePublication, setDatePublication] = useState();
  const [currentDepot, setCurrentDepot] = useState({
    TypeCandidature: null,
    domaineCompetition: null,
    categoriePrix: null,
    titreOeuvre: null,
    datePublication: null,
    resumeOeuvre: null,
  });

  const appel = [
    {
      titre: "Prix pour le jeune scientifique",
      dateOuverture: "26-12-2024",
      dateFermeture: "31-12-2024",
    },
    {
      titre: "Meilleur prix scientifique 2024",
      dateOuverture: "28-12-2024",
      dateFermeture: "31-12-2024",
    },
  ];

  useEffect(() => {
    setAppelCandidatures(appel);
  }, []);

  console.log(appelCandidatures);

  const handleChangeField = (value, fieldName) => {
    setCurrentDepot(currentDepot);
    currentDepot[`${fieldName}`] = value;
    setCurrentDepot(currentDepot);
  };

  const PostulerAppel = () => {
    setDepotPrixDialog(true);
    console.log(depotPrixDialog);
  };
  console.log(depotPrixDialog);

  const InfosCandidature = () => {
    //Code pour candidature
  };

  const hideDepotPrixDialog = () => {
    setDepotPrixDialog(false);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-file"
          className="p-button-succes"
          style={{ marginRight: ".5em" }}
          title="Postuler"
          onClick={() => PostulerAppel(rowData)}
        />
        <Button
          type="button"
          icon="pi pi-eye"
          className="p-button-success"
          style={{ marginRight: ".5em" }}
          title="Détails"
          onClick={() => InfosCandidature(rowData)}
        />
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-success"
          style={{ marginRight: ".5em" }}
          title="Modifier"
          onClick={() => InfosCandidature(rowData)}
        />
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          style={{ marginRight: ".5em" }}
          title="Supprimer"
          onClick={() => InfosCandidature(rowData)}
        />
      </React.Fragment>
    );
  };

  const depotPrixDialogFooter = (
    <>
        <div>
        <Button
        label="Quitter"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDepotPrixDialog}
        />
        <Button
        label="Soumettre"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDepotPrixDialog}
        />
        </div>,

        </>


 

  );

  const soumettreDepot = (
    <div>
      <Button
        label="Soumettre"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDepotPrixDialog}
      />
      <Button
        label="Annuler"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDepotPrixDialog}
      />
    </div>
  );

  return (
    <div className="p-grid">
     

       
        
              <DataTable
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                rows={10}
                selectionMode="single"
                filterMatchMode="contains"
                paginator
                responsive
                value={appelCandidatures}
                // header={
                //     "Liste des appels à candidature (" +
                //     this.state.listeEtudiantInscrits.length +*/
                //     ")"
                // }
                header="Liste des appels à candidature"
              >
                <Column
                  field="titre"
                  header="Appel à candidature"
                  headerStyle={{
                    textTransform: "capitalize",
                  }}
                  filterPlaceholder="Search ..."
                  sortable
                  filter
                />
                <Column
                  field="dateOuverture"
                  header="Date ouverture"
                  headerStyle={{
                    textTransform: "capitalize",
                  }}
                  filterPlaceholder="Search ..."
                  sortable
                  filter
                />
                <Column
                  field="dateFermeture"
                  header="Date fermeture"
                  headerStyle={{
                    textTransform: "capitalize",
                  }}
                  filterPlaceholder="Search ..."
                  sortable
                  filter
                />
                <Column
                  header={actionHeader}
                  body={actionBodyTemplate}
                  style={{
                    textAlign: "center",
                    width: "14em",
                  }}
                />
              </DataTable>

              {/* <div>
                <Button
                  label="Postuler"
                  type="button"
                  icon="pi pi-arrow-right"
                  className="p-button-success"
                  style={{ marginRight: ".5em" }}
                  title="Postuler"
                  onClick={() => PostulerAppel()}
                />
                <Button
                  label="Details"
                  type="button"
                  icon="pi pi-eye"
                  className="p-button-success"
                  style={{ marginRight: ".5em" }}
                  title="Détails"
                  onClick={() => InfosCandidature()}
                />
              </div> */}

              <Dialog
                visible={depotPrixDialog}
                style={{ width: "75%"}}
                header="Formulaire de candidature"
                modal className="p-fluid"
                footer={depotPrixDialogFooter}
                onHide={hideDepotPrixDialog}
              >
                
                <div className="p-grid">
                        <div className="p-field p-col-4">
                            
                            <label htmlFor="type">
                                Selectionner le type de candidature
                            </label>
                            <Dropdown
                                optionLabel=""
                                value=""
                                options=""
                                onChange={(e) => {}}
                                className={classNames({
                                "p-invalid": "this.state.submitted",
                                })}
                                placeholder=""
                            />
                            {/* {this.state.submitted && !this.state.client.prenom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>} */}
                            </div>
                            

                            <div className="p-field p-col-4">
                            <label htmlFor="username">
                                Selectionner le domaine de competition
                            </label>
                            <Dropdown
                                optionLabel=""
                                value=""
                                options=""
                                onChange={(e) => {}}
                                className={classNames({
                                "p-invalid": "this.state.submitted",
                                })}
                                placeholder=""
                            />
                            {/* {this.state.submitted && !this.state.client.prenom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>} */}
                            </div>

                        <div className="p-field p-col-4">
                            <label htmlFor="type">
                            Selectionner la categorie de prix
                            </label>
                            <Dropdown
                            optionLabel=""
                            value=""
                            options=""
                            onChange={(e) => {}}
                            className={classNames({
                                "p-invalid": "this.state.submitted",
                            })}
                            placeholder=""
                            />
                            {/* {this.state.submitted && !this.state.client.prenom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>} */}
                        </div>
                      </div>

                      

                      <div className="p-field p-col-12">
                        <label htmlFor="username">Titre de l'oeuvre</label>
                        <InputText
                          id="username"
                          onChange={(e) => {}}
                          className={classNames()}
                        />
                        {/* {this.state.submitted && !this.state.client.prenom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>} */}
                      
                      </div>


                      <div className="p-grid"> 
                      <div className="p-field p-col-4">
                          <label
                            htmlFor="resume"
                            style={{ display: "block", marginBottom: "0.5rem" }}
                           
                          >
                            Joindre le resumé de l'oeuvre
                          </label>
                          <FileUpload
                            mode="basic"
                            name="demo[]"
                            url="/api/upload"
                            accept="image/*"
                            maxFileSize={1000000}
                          />
                        </div>
                        

                        <div className="p-field p-col-8">
                          <label
                            htmlFor="datePub"
                            style={{ display: "block", marginBottom: "0.5rem", width: '80%' }}
                          >
                            Date de publication de l'oeuvre
                          </label>
                          <input
                            type="date"
                            value={datePublication}
                            onChange={handleChangeField}
                            max={new Date().toISOString().split("T")[0]}
                            style={{ width: "49%" }}
                          />
                          {/* {this.state.submitted && !this.state.client.prenom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>} */}
                        </div>
                        </div>
                       

                          {/* {this.state.submitted && !this.state.client.prenom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>} */}
                        
                  
                  

                <div className="card card-w-title datatable-demo">
                  
                    {/* <div className="p-field" style={{ marginLeft: 10 }}> */}
                      
                        <div
                          className="p-lg-12 card-body "
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <FileUpload
                            name="demo[]"
                            url={"/api/upload"}
                            multiple
                            accept="image/*"
                            maxFileSize={1000000}
                            emptyTemplate={
                              <p className="m-0">Joindre les documents.</p>
                            }
                          />
                        </div>
                        <div>
                          <DataTable
                            value={documentLists}
                            selectionMode="single"
                            header="Liste des documents joints à la candidature"
                            paginator={true}
                            rows={10}
                            responsive={true}
                          >
                            <Column
                              field="libelleParcours"
                              header="Nom"
                              filterMatchMode="contains"
                              sortable={true}
                              filter={true}
                              filterPlaceholder="Nom"
                            />
                            <Column
                              field="libelleAnneeAcademique"
                              header="Type"
                              filterMatchMode="contains"
                              sortable={true}
                              filter={true}
                              filterPlaceholder="Type"
                            />
                            <Column
                              field="libelleNiveau"
                              header="Chemin"
                              filterMatchMode="contains"
                              sortable={true}
                              filter={true}
                              filterPlaceholder="Chemin"
                            />
                            <Column
                              header={actionHeader}
                              body={actionBodyTemplate}
                              style={{ textAlign: "center", width: "12em" }}
                            />
                          </DataTable>
                        </div>
                      
                    {/* </div> */}
                  
                 
                </div>
              </Dialog>
            
          </div>
        
  );
}

export default DepotPrix;
