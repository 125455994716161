import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { TypeCategoriePrixService } from '../../service/TypeCategoriePrixService';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';

function TypeCategoriePrix() {
    let emptyTypeCategoriePrix = {
            id: null,
            libelle: ''
        };
    
        const [typeCategoriePrixs, setTypeCategoriePrixs] = useState([]);
        const [typeCategoriePrixDialog, setTypeCategoriePrixDialog] = useState(false);
        const [deleteTypeCategoriePrixDialog, setDeleteTypeCategoriePrixDialog] = useState(false);
        const [typeCategoriePrix, setTypeCategoriePrix] = useState(emptyTypeCategoriePrix);
        const [selectedTypeCategoriePrixs, setSelectedTypeCategoriePrixs] = useState(null);
        const [submitted, setSubmitted] = useState(false);
        const [globalFilter, setGlobalFilter] = useState(null);
        const [loading, setLoading] = useState(false);
        const dt = useRef(null);
        const typeCategoriePrixService = new TypeCategoriePrixService();
        const notify = new Notify();
        const spinner = new Spinner();
    
    
        useEffect(() => {
            
            setLoading(true)
            typeCategoriePrixService.getAll().then(data => {
                setTypeCategoriePrixs(data)
                setLoading(false);
                //notify.success();
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
                notify.echec(error.response.data.message);
            })
        }, []);
    
        const openNew = () => {
            setTypeCategoriePrix(emptyTypeCategoriePrix);
            setSubmitted(false);
            setTypeCategoriePrixDialog(true);
        }
    
        const hideDialog = () => {
            setSubmitted(false);
            setTypeCategoriePrixDialog(false);
        }
    
        const hideDeleteTypeCategoriePrixDialog = () => {
            setDeleteTypeCategoriePrixDialog(false);
        }
    
        const saveTypeCategoriePrix = () => {
            setSubmitted(true);
    
            if (typeCategoriePrix.libelle.trim()) {
                let _typeCategoriePrix = {...typeCategoriePrix};
                if (typeCategoriePrix.id) {
                    typeCategoriePrixService.update(typeCategoriePrix.id, _typeCategoriePrix).then(data => {
                        console.log(data);
                        typeCategoriePrixService.getAll().then(data => setTypeCategoriePrixs(data));
                        notify.success();
                    })
                    .catch(error => {
                        console.log(error);
                        notify.echec(error.response.data.message);
                    })
                }
                else {
                    typeCategoriePrixService.create(_typeCategoriePrix).then(data => {
                        console.log(data);
                        typeCategoriePrixService.getAll().then(data => setTypeCategoriePrixs(data));
                        notify.success();
                    })
                    .catch(error => {
                        console.log(error);
                        notify.echec(error.response.data.message);
                    })
                    
                }
    
                setTypeCategoriePrixDialog(false);
                setTypeCategoriePrix(emptyTypeCategoriePrix);
            }
        }
        const editTypeCategoriePrix = (typeCategoriePrix) => {
            setTypeCategoriePrix({...typeCategoriePrix});
            setTypeCategoriePrixDialog(true);
        }
    
        const confirmDeleteTypeCategoriePrix = (typeCategoriePrix) => {
            setTypeCategoriePrix(typeCategoriePrix);
            setDeleteTypeCategoriePrixDialog(true);
        }
    
        const deleteTypeCategoriePrix = () => {
            typeCategoriePrixService.delete(typeCategoriePrix.id).then(data => {
                setTypeCategoriePrix(emptyTypeCategoriePrix);
                console.log(data);
                typeCategoriePrixService.getAll().then(data => setTypeCategoriePrixs(data));
                notify.success();
            })
            .catch(error => {
                console.log(error);
                notify.echec(error.response.data.message);
            })
            setDeleteTypeCategoriePrixDialog(false);
            
        }
    
        const onChampsChange = (e, name) => {
            const val = (e.target && e.target.value).toUpperCase() || '';
            let _typeCategoriePrix = {...typeCategoriePrix};
            _typeCategoriePrix[`${name}`] = val;
    
            setTypeCategoriePrix(_typeCategoriePrix);
        }
    
        const actionBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editTypeCategoriePrix(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteTypeCategoriePrix(rowData)} style={{marginLeft : 5}} />
                </React.Fragment>
            );
        }
    
        const header = (
            <div className="table-header p-col-12">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                    <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                </span>
            </div>
        );
        const typeCategoriePrixDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveTypeCategoriePrix} />
            </React.Fragment>
        );
        const deleteTypeCategoriePrixDialogFooter = (
            <React.Fragment>
                <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTypeCategoriePrixDialog} />
                <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteTypeCategoriePrix} />
            </React.Fragment>
        );
        const actionHeader = <Button type="button" icon="pi pi-cog"/>;
  return (
    <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable ref={dt} value={typeCategoriePrixs} selection={selectedTypeCategoriePrixs} onSelectionChange={(e) => setSelectedTypeCategoriePrixs(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des Types catégoris de prix">

                    <Column field="libelle" header="Type Categorie Prix" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
                </DataTable>
                }
            </div>
            <Dialog visible={typeCategoriePrixDialog} style={{ width: '450px' }} header="Informations type Categorie Prix" modal className="p-fluid" footer={typeCategoriePrixDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="libelleTypeCategoriePrix">Libelle Type Categorie Prix</label>
                    <InputText id="libelleTypeCategoriePrix" value={typeCategoriePrix.libelle} onChange={(e) => onChampsChange(e, 'libelle')} required autoFocus className={classNames({ 'p-invalid': submitted && !typeCategoriePrix.libelle })} />
                    {submitted && !typeCategoriePrix.libelle && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteTypeCategoriePrixDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteTypeCategoriePrixDialogFooter} onHide={hideDeleteTypeCategoriePrixDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {typeCategoriePrix && <span>Etes vous sûr de supprimer cet élément ? <b>{typeCategoriePrix.libelle}</b>?</span>}
                </div>
            </Dialog>

        </div>
  )
}

export default TypeCategoriePrix