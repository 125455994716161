import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class DomaineService {

    constructor(){
        this.adresse = new AdresseIP()
        this.backendUrl="service/api/dgri/domaines-competition";
    }


    getAll() {
       
        return axios.get(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}`).then(res => res.data)
    }
   

    create(newDomaine) {
        return axios.post(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}`,newDomaine)
                   .then(res => res.data);
    }
   
    update(idDomaine ,newDomaine) {
       
        return axios.put(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}/${idDomaine}`, newDomaine)
                    .then(res => res.data);
    }

    delete(idDomaine) {
    
        return axios.patch(`${this.adresse.getAdresseIPPrixExcellence()}/${this.backendUrl}/${idDomaine}`)
                    .then(res => res.data)
    }


}