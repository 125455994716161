import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown'

import { TypeCategoriePrixService } from '../../service/TypeCategoriePrixService';
import { PrixService } from '../../service/PrixService';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InputTextarea } from 'primereact/inputtextarea';
import { DomaineService } from '../../../lmd/service/DomaineService';
import { AppelCandidatureService } from '../../service/AppelCandidatureService';

function AppelCandidature() {
  let emptyAppelCandidature = {
                id: null,
                communique: null,
                categoriePrix: null,
                domaine: null,
                dateOuverture: null,
                dateFermature: null,
                description: null
            };
        
            const [appelCandidatures, setAppelCandidatures] = useState([]);
            const [categoriePrixs, setCategoriePrixs] = useState([]);
            const [domaines, setDomaines] = useState([]);
            const [appelCandidatureDialog, setAppelCandidatureDialog] = useState(false);
            const [deleteAppelCandidatureDialog, setDeleteAppelCandidatureDialog] = useState(false);
            const [appelCandidature, setAppelCandidature] = useState(emptyAppelCandidature);
            const [selectedAppelCandidatures, setSelectedAppelCandidatures] = useState(null);
            const [submitted, setSubmitted] = useState(false);
            const [globalFilter, setGlobalFilter] = useState(null);
            const [loading, setLoading] = useState(false);
            const dt = useRef(null);
            const appelCandidatureService = new AppelCandidatureService();
            const domaineService = new DomaineService();
            const categoriePrixService = new PrixService();
            const notify = new Notify();
            const spinner = new Spinner();
        
        
            useEffect(() => {
                
                setLoading(true)
                appelCandidatureService.getAll().then(data => {
                    setAppelCandidatures(data)
                    setLoading(false);
                    //notify.success();
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                    notify.echec(error.response.data.message);
                })
  
                categoriePrixService.getAll().then(data => {
                  setCategoriePrixs(data)
                  
              })
              .catch(error => {
                  console.log(error);
              })
              domaineService.getAll().then(data => {
                setDomaines(data)
                
            })
            .catch(error => {
                console.log(error);
            })
            }, []);
        
            const openNew = () => {
                setAppelCandidature(emptyAppelCandidature);
                setSubmitted(false);
                setAppelCandidatureDialog(true);
            }
        
            const hideDialog = () => {
                setSubmitted(false);
                setAppelCandidatureDialog(false);
            }
        
            const hideDeleteAppelCandidatureDialog = () => {
                setDeleteAppelCandidatureDialog(false);
            }
        
            const saveAppelCandidature = () => {
                setSubmitted(true);
        
                if (appelCandidature.libelleAppelCandidature.trim()) {
                    let _appelCandidature = {...appelCandidature};
                    if (appelCandidature.idAppelCandidature) {
                        appelCandidatureService.update(appelCandidature.idAppelCandidature, _appelCandidature).then(data => {
                            console.log(data);
                            appelCandidatureService.getAll().then(data => setAppelCandidatures(data));
                            notify.success();
                        })
                        .catch(error => {
                            console.log(error);
                            notify.echec(error.response.data.message);
                        })
                    }
                    else {
                      appelCandidatureService.create(_appelCandidature).then(data => {
                            console.log(data);
                            appelCandidatureService.getAll().then(data => setAppelCandidatures(data));
                            notify.success();
                        })
                        .catch(error => {
                            console.log(error);
                            notify.echec(error.response.data.message);
                        })
                        
                    }
        
                    setAppelCandidatureDialog(false);
                    setAppelCandidature(emptyAppelCandidature);
                }
            }
            const editAppelCandidature = (appelCandidature) => {
                setAppelCandidature({...appelCandidature});
                setAppelCandidatureDialog(true);
            }
        
            const confirmDeleteAppelCandidature = (appelCandidature) => {
                setAppelCandidature(appelCandidature);
                setDeleteAppelCandidatureDialog(true);
            }
        
            const deleteAppelCandidature = () => {
                appelCandidatureService.delete(appelCandidature.idAppelCandidature).then(data => {
                    setAppelCandidature(emptyAppelCandidature);
                    console.log(data);
                    appelCandidatureService.getAll().then(data => setAppelCandidatures(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
                setDeleteAppelCandidatureDialog(false);
                
            }
        
            const onChampsChange = (e, name) => {
                const val = (e.target && e.target.value).toUpperCase() || '';
                let _appelCandidatureService = {...appelCandidatureService};
                _appelCandidatureService[`${name}`] = val;
        
                setAppelCandidature(_appelCandidatureService);
            }
        
            const actionBodyTemplate = (rowData) => {
                return (
                    <React.Fragment>
                        <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editAppelCandidature(rowData)} />
                        <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteAppelCandidature(rowData)} style={{marginLeft : 5}} />
                    </React.Fragment>
                );
            }
        
            const header = (
                <div className="table-header p-col-12">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                        <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                    </span>
                </div>
            );
            const appelCandidatureDialogFooter = (
                <React.Fragment>
                    <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                    <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveAppelCandidature} />
                </React.Fragment>
            );
            const deleteAppelCandidatureDialogFooter = (
                <React.Fragment>
                    <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteAppelCandidatureDialog} />
                    <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteAppelCandidature} />
                </React.Fragment>
            );
            const actionHeader = <Button type="button" icon="pi pi-cog"/>;
  return (
    <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable ref={dt} value={appelCandidatures} selection={selectedAppelCandidatures} onSelectionChange={(e) => setSelectedAppelCandidatures(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des appels de candidature">

                    <Column field="libelleAppelCandidature" header="Appel candidature" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
                </DataTable>
                }
            </div>
            <Dialog visible={appelCandidatureDialog}  header="Informations Appel Candidature" modal className="p-fluid" footer={appelCandidatureDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="libelleAppelCandidature">Titre</label>
                    <InputText id="libelleAppelCandidature" value={appelCandidature.libelleAppelCandidature} onChange={(e) => onChampsChange(e, 'libelleAppelCandidature')} required autoFocus className={classNames({ 'p-invalid': submitted && !appelCandidature.libelleAppelCandidature })} />
                    {submitted && !appelCandidature.libelleAppelCandidature && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="p-field p-grid p-col-12">
                  <div className='p-col-6'>
                        <label htmlFor="dateOuverture">Date d'ouverture</label>
                        <InputText type='date' value={appelCandidature.dateOuverture} onChange={(e) => onChampsChange(e, 'dateOuverture')} required className={classNames({ 'p-invalid': submitted && !appelCandidature.dateOuverture })} />
                        {submitted && !appelCandidature.dateOuverture && <small className="p-error">Ce champs est obligatoire.</small>}
                  </div>
                  <div className='p-col-6'>
                        <label htmlFor="dateFermeture">Date de fermeture</label>
                        <InputText type='date' value={appelCandidature.dateFermature} onChange={(e) => onChampsChange(e, 'dateFermeture')} required className={classNames({ 'p-invalid': submitted && !appelCandidature.dateFermature })} />
                        {submitted && !appelCandidature.dateFermature && <small className="p-error">Ce champs est obligatoire.</small>}
                  </div>  
                </div>
                <div className="p-field">
                  <label htmlFor="libelleTypeCategoriePrix">Communiqué</label>
                  <InputText type='file' value={appelCandidature.communique} onChange={(e) => onChampsChange(e, 'communique')} required className={classNames({ 'p-invalid': submitted && !appelCandidature.communique })} />
                  {submitted && !appelCandidature.communique && <small className="p-error">Ce champs est obligatoire.</small>}
                  
                </div>
                <div className="p-field  p-grid p-col-12">
                  <div className='p-col-6'>
                        <label htmlFor="libelleDomaine">Domaine</label>
                        <Dropdown selection='multiple' optionLabel="libelleDomaine" value={appelCandidature.domaine} options={domaines} onChange={(e) => onChampsChange(e, 'domaine')} required className={classNames({ 'p-invalid': submitted && !appelCandidature.domaine.idDomaine })} />
                        {submitted && !appelCandidature.domaine.idDomaine && <small className="p-error">Ce champs est obligatoire.</small>}
                  </div>
                  <div className='p-col-6'>
                        <label htmlFor="libelleCategoriePrix">Catégorie prix</label>
                        <Dropdown optionLabel="libelleCategoriePrix" value={appelCandidature.categoriePrix} options={categoriePrixs} onChange={(e) => onChampsChange(e, 'categoriePrix')} required className={classNames({ 'p-invalid': submitted && !appelCandidature.categoriePrix.idCategoriePrix })} />
                        {submitted && !appelCandidature.categoriePrix.idCategoriePrix  && <small className="p-error">Ce champs est obligatoire.</small>}
                  </div>
                </div>
                <div className="p-field">
                    <label htmlFor="description">Description</label>
                    <InputTextarea id="description" value={appelCandidature.description} onChange={(e) => onChampsChange(e, 'description')}  />
                </div>
            </Dialog>

            <Dialog visible={deleteAppelCandidatureDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteAppelCandidatureDialogFooter} onHide={hideDeleteAppelCandidatureDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {appelCandidature && <span>Etes vous sûr de supprimer cet élément ? <b>{appelCandidature.libelleAppelCandidature}</b>?</span>}
                </div>
            </Dialog>

        </div>
  )
}

export default AppelCandidature